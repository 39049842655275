'use client'

import React, { ReactNode, useEffect, useId, useRef } from "react"
import { v4 as uuid } from 'uuid'

type TabsProps = {
    tabs: ReactNode[],
    contents: ReactNode[],
    onChange: Function
}

export function Tabs({
    tabs,
    contents,
    onChange = () => {}
}: TabsProps) {

    const ref = useRef<HTMLDivElement>()
    const refId = useRef<string>(uuid())
    const id = useId().replaceAll(':', '')
    

    useEffect(() => {

        /*const load = async () => {
            const bootstrap = (await import('bootstrap/dist/js/bootstrap')).default;
            var triggerTabList = [].slice.call(document.querySelectorAll('#' + id + ' a'))
            console.log(triggerTabList)
            triggerTabList.forEach(function (triggerEl) {
                var tabTrigger = new bootstrap.Tab(triggerEl)

                console.log(tabTrigger)

                triggerEl.addEventListener('click', function (event) {
                    console.log(event)
                    event.preventDefault()
                    tabTrigger.show()
                    event.stopPropagation()
                })
            })
        }

        load()*/

        const controls = ref.current.querySelector(".js-tabs-controls");
        const controlsItems = ref.current.querySelectorAll(
            ".js-tabs-controls .js-tabs-button"
        );
        const content = ref.current.querySelector(".js-tabs-content");

        for (let l = 0; l < controlsItems.length; l++) {
            const el = controlsItems[l];

            el.addEventListener("click", (e) => {
                const selector = el.getAttribute("data-tab-target");

                controls
                    .querySelector(".is-tab-el-active")
                    .classList.remove("is-tab-el-active");
                content
                    .querySelector(".is-tab-el-active")
                    .classList.remove("is-tab-el-active");

                // console.log(controls.querySelector('.is-active'))
                // console.log(content.querySelector('.is-active'))

                el.classList.add("is-tab-el-active");
                content.querySelector(selector).classList.add("is-tab-el-active");

                onChange(l)
            });
        }
    }, [])

    return (
        <>
            <div ref={ref} className="tabs -underline js-tabs mt-20">
                <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 pb-0 js-tabs-controls">
                    <div className="d-flex flex-wrap x-gap-0 y-gap-20 justify-start">
                        {tabs.map((tab, indexTab) => {
                            return (
                                <React.Fragment key={'tab-' + indexTab}>
                                    <a className={"link all js-tabs-button" + (indexTab === 0 ? ' is-tab-el-active' : '')} data-tab-target={'#' + id + "_" + indexTab}>
                                        {tab}
                                    </a>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className="tabs__content js-tabs-content">
                    {contents.map((content, indexContent) => {
                        return (
                            <React.Fragment key={"pillcontent-" + indexContent}>
                                <div className={"tabs__pane -tab-item-1" + (indexContent === 0 ? " is-tab-el-active" : "")} id={id + '_' + indexContent}>
                                    {content}
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </>
    )
}