import { createObjectPagePathname, getAsServerMediaUrl, getMediaUrl } from "@/services/pathname"
import moment from 'moment'
import { levels } from "@/services/bafad"
import Image from "next/image"

type SmallCardProps = {
    aroeven: Aroeven,
    bafad: Bafad
}

export default function SmallCard({
    aroeven,
    bafad
}: SmallCardProps) {

    return (
        <>
            <a href={createObjectPagePathname('Bafad', bafad)} className={"vignette mini -type-1 bafa" + (bafad.availablePlaces === 0 ? ' opacity-50' : '')}>
                <div className="d-flex flex-row">
                    <div className="vignette__image">
                        <div className="cardImage ratio ratio-1:1">
                            <div className="cardImage__content">
                                {/*<img src={getMediaUrl(bafad.medias.filter(media => media.mimeType.indexOf('image/') === 0)[0]?.url ?? '#')} alt="image" />*/}
                                <Image
                                    src={getAsServerMediaUrl(bafad.medias.filter(media => media.mimeType.indexOf('image/') === 0)[0]?.url ?? '#')}
                                    width={bafad.medias.filter(media => media.mimeType.indexOf('image/') === 0)[0]?.metadata.width}
                                    height={bafad.medias.filter(media => media.mimeType.indexOf('image/') === 0)[0]?.metadata.height}
                                    alt='image'
                                    sizes="33vw"
                                    quality={50}
                                />
                            </div>
                        </div>
                        <div className="date">{moment(bafad.startDate1).format('DD' + (moment(bafad.startDate1).format('M') !== moment(bafad.endDate1).format('M') ? ' MMM' : ''))}-{moment(bafad.endDate1).format('DD MMM')}</div>
                    </div>
                    <div className="vignette__content">
                        <span className="text-light-1 lh-10 text-10">
                            <i className="fi fi-rr-marker" /> {bafad.accomodation.address.city} ({bafad.accomodation.address.postCode.slice(0, 2)})
                        </span>
                        <h4 className="vignette__title">
                            {bafad.level in levels ? levels[bafad.level].name : ''}
                        </h4>
                        {bafad.level === 'bafa_qualification' && bafad.thematicLabel && <div className="type">{bafad.thematicLabel}</div>}
                        <div><span className="badge rounded-pill bg-terracotta">COMPLET</span></div>
                    </div>
                </div>
            </a>
        </>
    )
}