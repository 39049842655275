'use client'

import React, { useEffect, useState, useRef } from "react"
import { Tabs } from "../ui/Tabs"
//import CommonSearchBar from '@/components/search/SearchBar'
import CommonSearchBar from '@/components/common/search/SearchBar'
import HolidayStaySearchBar from '@/components/holidayStay/SearchBar'
import BafadSearchBar from '@/components/bafad/SearchBar'
import EducationCourseSearchBar from '@/components/educationCourse/SearchBar'
import SchoolSearchBar from '@/components/school/SearchBar'
import { useRouter } from "next/navigation"
import { createObjectPagePathname, createSearchPagePathname } from "@/services/pathname"
import Link from "next/link"
import { searchAccomodations, searchBafads, searchEducationCourses, searchHolidayStays, searchSchoolActivities, searchSchoolThematics } from "@/api/helper";
import { useDebounceCallback } from "usehooks-ts"
import HolidayStaySmallCard from "@/components/holidayStay/SmallCard"
import BafadSmallCard from "@/components/bafad/SmallCard"
import EducationCourseSmallCard from "@/components/educationCourse/SmallCard"
import SchoolActivitySmallCard from "@/components/school/schoolActivity/SmallCard"
import Loading from "@/components/loading/Loading"
import { categories } from "@/services/educationCourse"
import { environments, thematics } from "@/services/holidayStay"
import { levels } from "@/services/bafad"
import moment from "moment"


type HomeSearchProps = {
    aroeven: Aroeven,
    holidayStayDepartures: string[],
    schoolStayAccomodations: Accomodation[]
}

export default function HomeSearch({
    aroeven,
    holidayStayDepartures,
    schoolStayAccomodations
}: HomeSearchProps) {

    const ref = useRef<HTMLElement>()

    const { push } = useRouter()

    const [query, setQuery] = useState<string>('')
    const [holidayStaySearch, setHolidayStaySearch] = useState({})
    const [bafadSearch, setBafadSearch] = useState({})
    const [educationCourseSearch, setEducationCourseSearch] = useState({})
    const [schoolSearch, setSchoolSearch] = useState({})
    const [searching, setSearching] = useState<boolean>(false)

    const [showResults, setShowResults] = useState<string>(null)
    const [results, setResults] = useState<any>([])

    const [currentTab, setCurrentTab] = useState<string>('Common')
    const changeQueryWithDebounce = useDebounceCallback(() => {
        search()
    }, 300)

    const holidayStayThematicsSuggestions = useRef<any[]>(Object.entries(thematics).sort((a, b) => 0.5 - Math.random()).slice(0, 3))
    const holidayStayDeparturesSuggestions = useRef<any[]>(holidayStayDepartures.sort((a, b) => 0.5 - Math.random()).slice(0, 3))
    const schoolStayAccomodationsSuggestions = useRef<Accomodation[]>(schoolStayAccomodations.sort((a, b) => 0.5 - Math.random()).slice(0, 3))

    useEffect(() => {
        addEventListener('focus', detectFocus, true)
        addEventListener('blur', detectBlur, true)
        ref.current.querySelector('.mega-menu.recherche').addEventListener('mouseleave', detectBlur)

        return (() => {
            removeEventListener('focus', detectFocus, true)
            removeEventListener('blur', detectBlur, true)
            ref.current?.querySelector('.mega-menu.recherche').removeEventListener('mouseleave', detectBlur)
        })
    }, [])

    useEffect(() => {
        search()
    }, [currentTab])

    useEffect(() => {
        changeQueryWithDebounce()
    }, [query])

    function detectFocus() {
        if (!document.activeElement) {
            return
        }
        if (document.activeElement.matches('input[type="text"]') === false) {
            return
        }
        const searchBar = document.activeElement.closest('.mainSearch')
        if (!searchBar) {
            return
        }
        setShowResults({
            multi: 'Common',
            sejour: 'HolidayStay',
            bafa: 'Bafad',
            educform: 'EducationCourse',
            thematique: 'School'
        }[searchBar.id] ?? null)
    }

    function detectBlur() {
        if (document.activeElement.matches('.mainSearch input[type="text"]') === false && ref.current?.querySelector('.mega-menu.recherche').matches(':hover') === false) {
            setShowResults(null)
        }
    }

    async function search() {
        setSearching(true)
        setResults([])
        const requests = {
            Common: [
                searchHolidayStays(aroeven, {
                    ...holidayStaySearch,
                    ...query?.trim() && { query },
                    publishedFor: [aroeven.slug],
                    minDate: moment().add(1, 'days').format('YYYY-MM-DD')
                }),
                searchBafads(aroeven, {
                    ...bafadSearch,
                    ...query?.trim() && { query },
                    publishedFor: [aroeven.slug],
                    minDate: moment().add(1, 'days').format('YYYY-MM-DD')
                }),
                searchEducationCourses(aroeven, { ...educationCourseSearch, ...query?.trim() && { query } }),
                searchSchoolThematics(aroeven, {
                    ...schoolSearch,
                    ...query?.trim() && { query },
                    active: true,
                    publishedFor: [aroeven.slug]
                }),
                searchSchoolActivities(aroeven, {
                    ...schoolSearch,
                    ...query?.trim() && { query },
                    publishedFor: [aroeven.slug]
                })
            ],
            HolidayStay: [
                searchHolidayStays(aroeven, {
                    ...holidayStaySearch,
                    ...query?.trim() && { query },
                    publishedFor: [aroeven.slug],
                    minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                }),
            ],
            Bafad: [
                searchBafads(aroeven, {
                    ...bafadSearch,
                    ...query?.trim() && { query },
                    publishedFor: [aroeven.slug],
                    minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                }),
            ],
            EducationCourse: [
                searchEducationCourses(aroeven, { ...educationCourseSearch, ...query?.trim() && { query } }),
            ],
            School: [
                searchSchoolThematics(aroeven, { ...schoolSearch, ...query?.trim() && { query }, active: true }),
                searchSchoolActivities(aroeven, { ...schoolSearch, ...query?.trim() && { query } }),
            ]
        }[currentTab] ?? []

        const results = await Promise.all(requests)
        setResults(results.filter(r => r))
        setSearching(false)
    }

    console.log('currentTab', currentTab)

    return (
        <>
            <section ref={ref} className="recherche multi">
                <div className="searchbar container">
                    <Tabs
                        tabs={[
                            <>
                                <i className="fi fi-rr-house-chimney" />
                                Tout rechercher
                            </>,
                            <>
                                <i className="fi fi-rr-camping" />
                                Séjours de vacances
                            </>,
                            <>
                                <i className="fi fi-rr-user-graduate" />
                                BAFA - BAFD
                            </>,
                            <>
                                <i className="fi fi-rr-users-class" />
                                Education - formation
                            </>,
                            <>
                                <i className="fi fi-rr-ruler-triangle" />
                                Séjours scolaires
                            </>
                        ]}
                        contents={[
                            <CommonSearchBar
                                search={{ query }}
                                setSearch={({ query }) => setQuery(query)}
                                handleSearch={() => {
                                    push(createSearchPagePathname('sector', 'Common', { ...query?.trim() && { query } }))
                                }}
                            />,
                            <HolidayStaySearchBar
                                departures={holidayStayDepartures}
                                search={{ ...holidayStaySearch, ...query?.trim() && { query } }}
                                setSearch={(search: any) => {
                                    const { query, ...otherSearchParams } = search
                                    setHolidayStaySearch(otherSearchParams)
                                    setQuery(query)
                                }}
                                handleSearch={() => {
                                    push(createSearchPagePathname('object', 'HolidayStay', { ...holidayStaySearch, ...query?.trim() && { query } }))
                                }}
                            />,
                            <BafadSearchBar
                                search={{ ...bafadSearch, ...query?.trim() && { query } }}
                                setSearch={(search: any) => {
                                    const { query, ...otherSearchParams } = search
                                    setBafadSearch(otherSearchParams)
                                    setQuery(query)
                                }}
                                handleSearch={() => {
                                    push(createSearchPagePathname('object', 'Bafad', { ...holidayStaySearch, ...query?.trim() && { query } }))
                                }}
                            />,
                            <EducationCourseSearchBar
                                search={{ ...educationCourseSearch, ...query?.trim() && { query } }}
                                setSearch={(search: any) => {
                                    const { query, ...otherSearchParams } = search
                                    setEducationCourseSearch(otherSearchParams)
                                    setQuery(query)
                                }}
                                handleSearch={() => {
                                    push(createSearchPagePathname('object', 'EducationCourse', { ...holidayStaySearch, ...query?.trim() && { query } }))
                                }}
                            />,
                            <SchoolSearchBar
                                search={{ ...schoolSearch, ...query?.trim() && { query } }}
                                setSearch={(search: any) => {
                                    const { query, ...otherSearchParams } = search
                                    setSchoolSearch(otherSearchParams)
                                    setQuery(query)
                                }}
                                handleSearch={() => {
                                    push(createSearchPagePathname('sector', 'School', { ...holidayStaySearch, ...query?.trim() && { query } }))
                                }}
                            />,
                        ]}
                        onChange={(indexTab: number) => {
                            setCurrentTab(['Common', 'HolidayStay', 'Bafad', 'EducationCourse', 'School'][indexTab] ?? 'Common')
                        }}
                    />
                </div>
                <div className={"mega-menu recherche " + ({
                    Common: '',
                    HolidayStay: 'sejours',
                    Bafad: 'bafa',
                    EducationCourse: 'educform',
                    School: 'scolaires'
                }[currentTab] ?? '') + " v2 " + (showResults !== null ? '' : 'd-none')} style={{ marginTop: 15 }}>
                    <div className="container-fluid container-xxl">
                        <div className="mega">
                            <div className="mega__content">
                                {searching &&
                                    <Loading />
                                }
                                {currentTab === 'Common' ?
                                    <>
                                        <div className="mega__grid">
                                            {results.map((result, indexResult) => {
                                                if (result.items.length === 0) {
                                                    return <React.Fragment key={'result-' + indexResult}></React.Fragment>
                                                }
                                                const { context } = result
                                                return (
                                                    <React.Fragment key={'result-' + indexResult}>
                                                        {context === 'HolidayStay' &&
                                                            <>
                                                                <div className="mega__item gap-2">
                                                                    <div className="d-flex flex-row gap-3">
                                                                        <div>
                                                                            <h4>
                                                                                <i className="fi fi-rr-camping" /> Séjours de vacances
                                                                            </h4>
                                                                            {result.items.slice(0, 3).map((item, indexItem) => {
                                                                                return (
                                                                                    <div key={'item-' + indexItem}>
                                                                                        <HolidayStaySmallCard aroeven={aroeven} holidayStay={item} />
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        <div className="mt-35">
                                                                            <h5>Thématiques</h5>
                                                                            <ul>
                                                                                {holidayStayThematicsSuggestions.current.map(([keyThematic, thematic], indexThematic) => {
                                                                                    return (
                                                                                        <React.Fragment key={'thematic-' + indexThematic}>
                                                                                            <li className="liste-simple">
                                                                                                <Link href={createSearchPagePathname('object', 'HolidayStay', {
                                                                                                    thematic: keyThematic
                                                                                                })}>{thematic.name}</Link>
                                                                                            </li>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                            <h5>Lieu</h5>
                                                                            <ul>
                                                                                {Object.entries(environments).map(([keyEnvironment, environment], indexEnvironment) => {
                                                                                    return (
                                                                                        <React.Fragment key={'environment-' + indexEnvironment}>
                                                                                            <li className="liste-simple">
                                                                                                <Link href={createSearchPagePathname('object', 'HolidayStay', {
                                                                                                    environment: keyEnvironment
                                                                                                })}>{environment.name}</Link>
                                                                                            </li>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                            <h5>Au départ de</h5>
                                                                            <ul>
                                                                                {holidayStayDeparturesSuggestions.current.map((departure, indexDeparture) => {
                                                                                    return (
                                                                                        <React.Fragment key={'thematic-' + indexDeparture}>
                                                                                            <li className="liste-simple">
                                                                                                <Link href={createSearchPagePathname('object', 'HolidayStay', {
                                                                                                    departures: departure
                                                                                                })}>{departure}</Link>
                                                                                            </li>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <Link href={createSearchPagePathname('object', 'HolidayStay', { ...holidayStaySearch, ...(query?.trim() && { query }) })} className="button-primary d-block w-100 mt-10">
                                                                        Tous les séjours de vacances
                                                                    </Link>
                                                                </div>
                                                            </>
                                                        }
                                                        {context === 'Bafad' &&
                                                            <>
                                                                <div className="mega__item gap-2">
                                                                    <div className="d-flex flex-row gap-3">
                                                                        <div>
                                                                            <h4>
                                                                                <i className="fi fi-rr-user-graduate" /> BAFA - BAFD
                                                                            </h4>
                                                                            {result.items.slice(0, 3).map((item, indexItem) => {
                                                                                return (
                                                                                    <div key={"item-" + indexItem}>
                                                                                        <BafadSmallCard aroeven={aroeven} bafad={item} />
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            <Link href={createSearchPagePathname('object', 'Bafad', { ...bafadSearch, ...(query?.trim() && { query }) })} className="button-primary d-block w-100 mt-10">
                                                                                Toutes les formations
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {context === 'EducationCourse' &&
                                                            <>
                                                                <div className="mega__item">
                                                                    <div>
                                                                        <h4>
                                                                            <i className="fi fi-rr-users-class" /> Education - formation
                                                                        </h4>
                                                                        <div className="ml-5">
                                                                            <ul>
                                                                                {result.items.slice(0, 5).map((item, indexItem) => {
                                                                                    return (
                                                                                        <li key={"item-" + indexItem} className="liste-simple theme1">
                                                                                            <Link href={createObjectPagePathname('EducationCourse', item)}>{item.name}</Link>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {context === 'SchoolActivity' &&
                                                            <>
                                                                <div className="mega__item">
                                                                    <h4>
                                                                        <i className="fi fi-rr-ruler-triangle" /> Séjours scolaires
                                                                    </h4>
                                                                    {result.items.slice(0, 3).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={"item-" + indexItem}>
                                                                                <SchoolActivitySmallCard aroeven={aroeven} schoolActivity={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className="mega__item">
                                                                    <h5 className="mt-35">Hébergements</h5>
                                                                    <div>
                                                                        <ul>
                                                                            {schoolStayAccomodationsSuggestions.current.map((accomodation, indexAccomodation) => {
                                                                                return (
                                                                                    <React.Fragment key={'accomodation-' + indexAccomodation}>
                                                                                        <li className="liste-simple">
                                                                                            <Link href={createObjectPagePathname('SchoolStay', accomodation)}>{accomodation.name}</Link>
                                                                                        </li>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                    <h5>Thématiques</h5>
                                                                    <div>
                                                                        <ul>
                                                                            {result.items.slice(0, 3).map((item, indexItem) => {
                                                                                return (
                                                                                    <li key={'item-' + indexItem} className="liste-simple">
                                                                                        <Link href={createObjectPagePathname('SchoolThematic', item)}>{item.name}</Link>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </>
                                    :
                                    <>
                                        {currentTab === 'HolidayStay' && results[0]?.context === 'HolidayStay' && typeof console.log(results[0].items) &&
                                            <>
                                                <div className="mega__grid">
                                                    {results[0].items.slice(0, 3).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div>
                                                                    <h4>
                                                                        <i className="fi fi-rr-camping" /> Séjours de vacances
                                                                    </h4>
                                                                    {results[0].items.slice(0, 3).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <HolidayStaySmallCard aroeven={aroeven} holidayStay={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {results[0].items.slice(3, 6).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div className="mt-35">
                                                                    {results[0].items.slice(3, 6).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <HolidayStaySmallCard aroeven={aroeven} holidayStay={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {results[0].items.slice(6, 9).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div className="mt-35">
                                                                    {results[0].items.slice(6, 9).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <HolidayStaySmallCard aroeven={aroeven} holidayStay={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="mega__item gap-2">
                                                        <div className="d-flex flex-row gap-3">
                                                            <div className="mt-35">
                                                                <h5>Activités</h5>
                                                                <ul>
                                                                    {holidayStayThematicsSuggestions.current.map(([keyThematic, thematic], indexThematic) => {
                                                                        return (
                                                                            <React.Fragment key={'thematic-' + indexThematic}>
                                                                                <li className="liste-simple">
                                                                                    <Link href={createSearchPagePathname('object', 'HolidayStay', {
                                                                                        thematic: keyThematic
                                                                                    })}>{thematic.name}</Link>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <h5>Lieu</h5>
                                                                <ul>
                                                                    {Object.entries(environments).map(([keyEnvironment, environment], indexEnvironment) => {
                                                                        return (
                                                                            <React.Fragment key={'environment-' + indexEnvironment}>
                                                                                <li className="liste-simple">
                                                                                    <Link href={createSearchPagePathname('object', 'HolidayStay', {
                                                                                        environment: keyEnvironment
                                                                                    })}>{environment.name}</Link>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <h5>Au départ de</h5>
                                                                <ul>
                                                                    {holidayStayDeparturesSuggestions.current.map((departure, indexDeparture) => {
                                                                        return (
                                                                            <React.Fragment key={'thematic-' + indexDeparture}>
                                                                                <li className="liste-simple">
                                                                                    <Link href={createSearchPagePathname('object', 'HolidayStay', {
                                                                                        departures: departure
                                                                                    })}>{departure}</Link>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link href={createSearchPagePathname('object', 'HolidayStay')} className="button-primary d-block mt-10 ms-auto">Tous les séjours de vacances</Link>
                                            </>
                                        }
                                        {currentTab === 'Bafad' && results[0]?.context === 'Bafad' &&
                                            <>
                                                <div className="mega__grid">
                                                    {results[0].items.slice(0, 3).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div>
                                                                    <h4>
                                                                        <i className="fi fi-rr-user-graduate"></i> BAFA - BAFD
                                                                    </h4>
                                                                    {results[0].items.slice(0, 3).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <BafadSmallCard aroeven={aroeven} bafad={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {results[0].items.slice(3, 6).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div className="mt-35">
                                                                    {results[0].items.slice(3, 6).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <BafadSmallCard aroeven={aroeven} bafad={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {results[0].items.slice(6, 9).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div className="mt-35">
                                                                    {results[0].items.slice(6, 9).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <BafadSmallCard aroeven={aroeven} bafad={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="mega__item gap-2">
                                                        <div className="d-flex flex-row gap-3">
                                                            <div className="mt-35">
                                                                <h5>Formations</h5>
                                                                <ul>
                                                                    {Object.entries(levels).filter(([keyLevel, level]) => level.searchable === true).map(([keyLevel, level], indexLevel) => {
                                                                        return (
                                                                            <React.Fragment key={'level-' + indexLevel}>
                                                                                <li className="liste-simple">
                                                                                    <Link href={createSearchPagePathname('object', 'Bafad', {
                                                                                        level: keyLevel
                                                                                    })}>{level.name}</Link>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link href={createSearchPagePathname('object', 'Bafad')} className="button-primary d-block mt-10 ms-auto">Toutes les formations BAFA-BAFD</Link>
                                            </>
                                        }
                                        {currentTab === 'EducationCourse' && results[0]?.context === 'EducationCourse' &&
                                            <>
                                                <div className="mega__grid">
                                                    {results[0].items.slice(0, 3).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div>
                                                                    <h4>
                                                                        <i className="fi fi-rr-users-class"></i> Education - Formation
                                                                    </h4>
                                                                    {results[0].items.slice(0, 3).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <EducationCourseSmallCard aroeven={aroeven} educationCourse={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {results[0].items.slice(3, 6).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div className="mt-35">
                                                                    {results[0].items.slice(3, 6).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <EducationCourseSmallCard aroeven={aroeven} educationCourse={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {results[0].items.slice(6, 9).length > 0 &&
                                                        <div className="mega__item gap-2">
                                                            <div className="d-flex flex-row gap-3">
                                                                <div className="mt-35">
                                                                    {results[0].items.slice(6, 9).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <EducationCourseSmallCard aroeven={aroeven} educationCourse={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="mega__item gap-2">
                                                        <div className="d-flex flex-row gap-3">
                                                            <div className="mt-35">
                                                                <h5>Thématiques</h5>
                                                                <ul>
                                                                    {Object.entries(categories).map(([keyCategory, category], indexCategory) => {
                                                                        return (
                                                                            <li key={'category-' + indexCategory} className="liste-simple">
                                                                                <Link href={createSearchPagePathname('object', 'EducationCourse', {
                                                                                    category: keyCategory,
                                                                                    ...query && { query }
                                                                                })}>{category.name}</Link>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <h5>Public</h5>
                                                                <ul>
                                                                    <li className="liste-simple">
                                                                        <Link href={createSearchPagePathname('object', 'EducationCourse', {
                                                                            target: 'children',
                                                                            ...query && { query }
                                                                        })}>Enfants / adolescents</Link>
                                                                    </li>
                                                                    <li className="liste-simple">
                                                                        <Link href={createSearchPagePathname('object', 'EducationCourse', {
                                                                            target: 'adults',
                                                                            ...query && { query }
                                                                        })}>Adultes</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <Link href={createSearchPagePathname('object', 'EducationCourse')} className="button-primary d-block mt-10 ms-auto">Toutes les formations</Link>
                                            </>
                                        }
                                        {currentTab === 'School' && results[0]?.context === 'SchoolThematic' && results[1]?.context === 'SchoolActivity' &&
                                            <>
                                                <div className="mega__grid">
                                                    {results[1].items.slice(0, 3).length > 0 &&
                                                        <div className="mega__item">
                                                            <h4>
                                                                <i className="fi fi-rr-ruler-triangle"></i> Séjours scolaires
                                                            </h4>
                                                            {results[1].items.slice(0, 3).map((item, indexItem) => {
                                                                return (
                                                                    <div key={'item-' + indexItem}>
                                                                        <SchoolActivitySmallCard aroeven={aroeven} schoolActivity={item} />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                    {results[1].items.slice(3, 6).length > 0 &&
                                                        <div className="mega__item">
                                                            {results[1].items.slice(3, 6).length > 0 &&

                                                                <div className="mt-35">
                                                                    {results[1].items.slice(3, 6).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <SchoolActivitySmallCard aroeven={aroeven} schoolActivity={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                            }
                                                        </div>
                                                    }
                                                    {results[1].items.slice(6, 9).length > 0 &&
                                                        <div className="mega__item">
                                                            {results[1].items.slice(6, 9).length > 0 &&

                                                                <div className="mt-35">
                                                                    {results[1].items.slice(6, 9).map((item, indexItem) => {
                                                                        return (
                                                                            <div key={'item-' + indexItem}>
                                                                                <SchoolActivitySmallCard aroeven={aroeven} schoolActivity={item} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                            }
                                                        </div>
                                                    }
                                                    <div className="mega__item">
                                                        <h5 className="mt-35">Hébergements</h5>
                                                        <div>
                                                            <ul>
                                                                {schoolStayAccomodationsSuggestions.current.map((accomodation, indexAccomodation) => {
                                                                    return (
                                                                        <React.Fragment key={'accomodation-' + indexAccomodation}>
                                                                            <li className="liste-simple">
                                                                                <Link href={createObjectPagePathname('SchoolStay', accomodation)}>{accomodation.name}</Link>
                                                                            </li>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                        {results[0].items.length > 0 &&
                                                            <>
                                                                <h5>Thématiques</h5>
                                                                <div>
                                                                    <ul>
                                                                        {results[0].items.slice(0, 3).map((item, indexItem) => {
                                                                            return (
                                                                                <li key={'item-' + indexItem} className="liste-simple">
                                                                                    <Link href={createObjectPagePathname('SchoolThematic', item)}>{item.name}</Link>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                                {/*<div className="mega__item">
                                        <div >
                                            <h4>
                                                <i className="fi fi-rr-users-class" />
                                                Education - formation
                                            </h4>
                                            <div className="ml-5">
                                                <ul>
                                                    <li className="liste-simple theme1">
                                                        <a href="#">Accompagner des Projets Jeunes</a>
                                                    </li>
                                                    <li className="liste-simple theme1">
                                                        <a href="#">Accompagner des Projets Jeunes</a>
                                                    </li>
                                                    <li className="liste-simple theme2">
                                                        <a href="#">Accompagner des Projets Jeunes</a>
                                                    </li>
                                                    <li className="liste-simple theme2">
                                                        <a href="#">Accompagner des Projets Jeunes</a>
                                                    </li>
                                                    <li className="liste-simple theme2">
                                                        <a href="#">Accompagner des Projets Jeunes</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mega__item">
                                        <h4>
                                            <i className="fi fi-rr-ruler-triangle" />
                                            Séjours scolaires
                                        </h4>
                                        <div>
                                            <a href="#" className="vignette mini -type-1 scolaire">
                                                <div className="d-flex flex-row">
                                                    <div className="vignette__image">
                                                        <div className="cardImage ratio ratio-1:1">
                                                            <div className="cardImage__content">
                                                                <img src="img/gallery/scolaire-1.jpg" alt="image" />
                                                            </div>
                                                            <div className="cardImage__leftBadge">
                                                                <div className="tag">
                                                                    <h3 className="tag-title">Cycle 1</h3>
                                                                    <div className="tag-tail" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="date">
                                                            <i className="fi fi-bs-pending" />
                                                            2h
                                                        </div>
                                                    </div>
                                                    <div className="vignette__content">
                                                        <span className="text-light-1 lh-10 text-10">
                                                            <i className="fi fi-rr-marker" />
                                                            Nompatelize (88)
                                                        </span>
                                                        <h4 className="vignette__title">
                                                            Foret sensorielle ou p’tites bêtes de la litière
                                                        </h4>
                                                        <div className="type">
                                                            Etres vivants et biodiversité
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="#" className="vignette mini -type-1 scolaire">
                                                <div className="d-flex flex-row">
                                                    <div className="vignette__image">
                                                        <div className="cardImage ratio ratio-1:1">
                                                            <div className="cardImage__content">
                                                                <img src="img/gallery/scolaire-1.jpg" alt="image" />
                                                            </div>
                                                            <div className="cardImage__leftBadge">
                                                                <div className="tag">
                                                                    <h3 className="tag-title">Cycle 1</h3>
                                                                    <div className="tag-tail" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="date">
                                                            <i className="fi fi-bs-pending" />
                                                            2h
                                                        </div>
                                                    </div>
                                                    <div className="vignette__content">
                                                        <span className="text-light-1 lh-10 text-10">
                                                            <i className="fi fi-rr-marker" />
                                                            Nompatelize (88)
                                                        </span>
                                                        <h4 className="vignette__title">
                                                            Foret sensorielle ou p’tites bêtes de la litière
                                                        </h4>
                                                        <div className="type">
                                                            Etres vivants et biodiversité
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="#" className="vignette mini -type-1 scolaire">
                                                <div className="d-flex flex-row">
                                                    <div className="vignette__image">
                                                        <div className="cardImage ratio ratio-1:1">
                                                            <div className="cardImage__content">
                                                                <img src="img/gallery/scolaire-1.jpg" alt="image" />
                                                            </div>
                                                            <div className="cardImage__leftBadge">
                                                                <div className="tag">
                                                                    <h3 className="tag-title">Cycle 1</h3>
                                                                    <div className="tag-tail" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="date">
                                                            <i className="fi fi-bs-pending" />
                                                            2h
                                                        </div>
                                                    </div>
                                                    <div className="vignette__content">
                                                        <span className="text-light-1 lh-10 text-10">
                                                            <i className="fi fi-rr-marker" />
                                                            Nompatelize (88)
                                                        </span>
                                                        <h4 className="vignette__title">
                                                            Foret sensorielle ou p’tites bêtes de la litière
                                                        </h4>
                                                        <div className="type">
                                                            Etres vivants et biodiversité
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="mega__item">
                                        <h5 className="mt-35">Hébergements</h5>
                                        <div >
                                            <ul>
                                                <li className="liste-simple">
                                                    <a href="#">Centre de vacances Poule et poils</a>
                                                </li>
                                                <li className="liste-simple">
                                                    <a href="#">Centre de vacances Poule et poils</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <h5>Thématiques</h5>
                                        <div >
                                            <ul>
                                                <li className="liste-simple">
                                                    <a href="#">Histoire et patrimoine</a>
                                                </li>
                                                <li className="liste-simple">
                                                    <a href="#">Histoire et patrimoine</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}