'use client'
import React, { ReactElement} from "react";
import { useEffect, useRef, ReactNode } from "react";
import VendorSwiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css';
import moment from "moment";
import 'moment/locale/fr'
import HolidayStayCard from "@/components/holidayStay/Card";
import BafadCard from "@/components/bafad/Card";

type SwiperProps = {
    className?: string,
    children: ReactNode,
    theme?: string
    pagination?: string
}

export default function Swiper({
    className = "",
    children,
    theme = '',
    pagination: paginationDef = 'auto'
}: SwiperProps) {

    const ref = useRef<HTMLInputElement>(null)
    const refSwipper = useRef<VendorSwiper>(null)

    useEffect(() => {
        const el = ref.current;

        let prevNavElement = el.querySelector(".js-prev");
        let nextNavElement = el.querySelector(".js-next");

        if (el.getAttribute("data-nav-prev"))
            prevNavElement = el.querySelector(
                `.${el.getAttribute("data-nav-prev")}`
            );
        if (el.getAttribute("data-nav-next"))
            nextNavElement = el.querySelector(
                `.${el.getAttribute("data-nav-next")}`
            );

        let gap = 0;
        let loop = false;
        let centered = false;
        let pagination: {} | boolean = false;
        let scrollbar: {} | boolean = false;

        if (el.getAttribute("data-gap")) gap = parseInt(el.getAttribute("data-gap"));
        if (el.hasAttribute("data-loop")) loop = true;
        if (el.hasAttribute("data-center")) centered = true;

        if (el.getAttribute("data-pagination")) {
            let paginationElement = el.querySelector(
                `.${el.getAttribute("data-pagination")}`
            );

            pagination = {
                el: paginationElement,
                bulletClass: "pagination__item",
                bulletActiveClass: "is-active",
                bulletElement: "div",
                clickable: true,
            };
        }

        if (el.hasAttribute("data-scrollbar")) {
            scrollbar = {
                el: ".js-scrollbar",
                draggable: true,
            };
        }

        const colsArray = el.getAttribute("data-slider-cols").split(" ");

        let cols_base = 1;
        let cols_xl = 1;
        let cols_lg = 1;
        let cols_md = 1;
        let cols_sm = 1;

        colsArray.forEach((el) => {
            if (el.includes("base")) cols_base = parseInt(el.slice(-1));
            if (el.includes("xl")) cols_xl = parseInt(el.slice(-1));
            if (el.includes("lg")) cols_lg = parseInt(el.slice(-1));
            if (el.includes("md")) cols_md = parseInt(el.slice(-1));
            if (el.includes("sm")) cols_sm = parseInt(el.slice(-1));
        });

        let height = 0
        for (const child of ref.current.children) {
            height = Math.max(height, (child as HTMLElement).offsetHeight)
        }

        refSwipper.current = new VendorSwiper(el, {
            modules: [Navigation, Pagination],

            speed: 600,

            //autoHeight: true,
            height,

            centeredSlides: centered,
            parallax: true,
            watchSlidesProgress: true,
            loop: loop,
            //loopAdditionalSlides: 4,            

            scrollbar: scrollbar,
            pagination: pagination,
            spaceBetween: 30,

            //slidesPerView: cols_base,
            /*breakpoints: {
                1399: {
                    slidesPerView: cols_xl,
                    width: null,
                    spaceBetween: gap,
                },
                1199: {
                    slidesPerView: cols_xl,
                    width: null,
                    spaceBetween: gap,
                },
                991: {
                    slidesPerView: cols_lg,
                    width: null,
                    spaceBetween: gap,
                },
                767: {
                    slidesPerView: cols_md,
                    width: null,
                    spaceBetween: gap,
                },
                574: {
                    slidesPerView: cols_sm,
                    width: null,
                    spaceBetween: gap,
                },
            },*/

            slidesPerView: 'auto',


            navigation: {
                prevEl: prevNavElement as HTMLElement,
                nextEl: nextNavElement as HTMLElement,
            },
        });

        if (paginationDef === 'unit') {            
            refSwipper.current.on('slideChangeTransitionEnd', () => {
                console.log('ontranstionend')
                ref.current.querySelector('.pagination__item.is-active')?.classList.remove('is-active')
                ref.current.querySelectorAll('.pagination__item')[refSwipper.current.realIndex]?.classList.add('is-active')
            })
        }
        
    }, [])


    return (
        <div
            className={className}
            data-gap="30"
            data-scrollbar
            data-slider-cols="xl-4 lg-3 md-2 sm-2 base-1"
            data-nav-prev="js-resultats-prev"
            data-pagination="js-resultats-pag"
            data-nav-next="js-resultats-next"
            ref={ref}
        >
            <div className="swiper-wrapper">
                {children}
                {/*{React.Children.map(children, (child: ReactElement, indexChild) => {          
                    return (
                        <React.Fragment key={"child-" + indexChild}>
                            <div className="swiper-slide">
                                {child}
                            </div>
                        </React.Fragment>
                    )
                })}*/}
            </div>
            {paginationDef === 'auto' &&
                <div className={"d-flex x-gap-15 items-center justify-center pt-10 " + theme}>
                    <div className="col-auto">
                        <button className="vignettes-nav d-flex items-center text-24 arrow-left-hover js-resultats-prev">
                            <i className="icon icon-arrow-left" />
                        </button>
                    </div>
                    <div className="col-auto swiper-bullets">
                        <div className="pagination -dots text-border js-resultats-pag" />
                    </div>
                    <div className="col-auto">
                        <button className="vignettes-nav d-flex items-center text-24 arrow-right-hover js-resultats-next">
                            <i className="icon icon-arrow-right" />
                        </button>
                    </div>
                </div>
            }
            {paginationDef === 'unit' &&
                <div className="pt-60 lg:pt-40">
                    <div className="pagination -avatars row x-gap-40 y-gap-20 justify-center js-testimonials-pagination">
                        {React.Children.map(children, (child, indexChild) => {
                            return (
                                <React.Fragment key={"child-" + indexChild}>
                                    <div className="col-auto">
                                        <div className={"pagination__item" + (indexChild === 0 ? " is-active" : "")} onClick={(e) => {
                                            ref.current.querySelector('.pagination__item.is-active')?.classList.remove('is-active')
                                            ref.current.querySelectorAll('.pagination__item')[indexChild]?.classList.add('is-active')
                                            refSwipper.current.slideTo(indexChild)
                                        }}>
                                            <i className="fi fi-ss-circle" />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}                        
                    </div>
                </div>
            }
        </div>
    )
}