import moment from "moment"
import { createObjectPagePathname, getMediaUrl } from "@/services/pathname"
import 'moment/locale/fr'
moment.locale('fr')
import { environments, getNextSessions } from "@/services/holidayStay"
import Link from "next/link"

type SmallCardProps = {
    aroeven: Aroeven,
    holidayStay: HolidayStay
}

export default function SmallCard({
    aroeven,
    holidayStay
}: SmallCardProps) {

    const nextSessions = getNextSessions(holidayStay)

    return (
        <>
            <Link href={createObjectPagePathname('HolidayStay', holidayStay)} className="vignette mini -type-1 sejour">
                <div className="d-flex flex-row">
                    <div className="vignette__image">
                        <div className="cardImage ratio ratio-1:1">
                            <div className="cardImage__content">
                                <img src={getMediaUrl(holidayStay.medias.filter(media => media.mimeType.indexOf('image/') === 0)[0]?.url ?? '#')} alt="image" />
                            </div>
                            <div className="cardImage__leftBadge">
                                <div className="tag">
                                    <h3 className="tag-title">{holidayStay.minAge}{holidayStay.minAge !== holidayStay.maxAge && <>-{holidayStay.maxAge}</>} ans</h3>
                                    <div className="tag-tail" />
                                </div>
                            </div>
                        </div>
                        {nextSessions.length > 0 && <div className="date">{moment(nextSessions[0].startDate).format('DD' + (moment(nextSessions[0].startDate).format('M') !== moment(nextSessions[0].endDate).format('M') ? ' MMM' : ''))}-{moment(nextSessions[0].endDate).format('DD MMM')}</div>}
                    </div>
                    <div className="vignette__content">
                        {holidayStay.accomodations.length > 0 &&
                            <span className="text-light-1 lh-10 text-10">
                                <i className="fi fi-rr-marker" /> {holidayStay.accomodations[0].accomodation.address.city} ({holidayStay.accomodations[0].accomodation.address.postCode.slice(0, 2)})
                            </span>
                        }
                        <h4 className="vignette__title">{holidayStay.name}</h4>
                        {holidayStay.environment in environments && <div className="type">{environments[holidayStay.environment].name}</div>}
                    </div>
                </div>
            </Link>
        </>
    )
}