import moment from "moment"
import { createObjectPagePathname, getMediaUrl } from "@/services/pathname"
import 'moment/locale/fr'
moment.locale('fr')
import Link from "next/link"
import img from "@/assets/img/gallery/scolaire-1.jpg"

type SmallCardProps = {
    aroeven: Aroeven,
    schoolActivity: SchoolActivity
}

export default function SmallCard({
    aroeven,
    schoolActivity
}: SmallCardProps) {

    return (
        <>
            <Link href={createObjectPagePathname('SchoolActivity', schoolActivity)} className="vignette mini -type-1 scolaire">
                <div className="d-flex flex-row">
                    <div className="vignette__image">
                        <div className="cardImage ratio ratio-1:1">
                            <div className="cardImage__content">
                                <img src={getMediaUrl(schoolActivity.media?.url ?? '#')} alt="image" />
                            </div>
                            <div className="cardImage__leftBadge">
                                <div className="tag">
                                    <h3 className="tag-title">Cycle 1</h3>
                                    <div className="tag-tail"></div>
                                </div>
                            </div>
                        </div>
                        <div className="date">
                            <i className="fi fi-bs-pending"></i> 2h
                        </div>
                    </div>
                    <div className="vignette__content">
                        <span className="text-light-1 lh-10 text-10">
                            <i className="fi fi-rr-marker"></i> {schoolActivity.thematic.accomodation.address.city} ({schoolActivity.thematic.accomodation.address.postCode.slice(0, 2)})
                        </span>

                        <h4 className="vignette__title">{schoolActivity.name}</h4>

                        <div className="type">Etres vivants et biodiversité</div>
                    </div>
                </div>
            </Link>
        </>
    )
}