'use client'

import Dropdown from "@/components/form/Dropdown"
import { convertSearchParamToArray } from "@/services/search"
import { useSearchBar } from "@/components/search/useSearchBar"

type SearchBarProps = {
    search?: any,
    setSearch?: Function,
    handleSearch?: Function,
    departures: string[]
}

export default function SearchBar({
    search,
    setSearch,
    handleSearch,
    departures
}: SearchBarProps) {

    useSearchBar()
    
    const seasons = {
        'hiver': {
            name: 'Hiver',
            months: [0, 1],
        },
        'printemps': {
            name: 'Printemps',
            months: [2, 3],
        },
        'ete': {
            name: 'Eté',
            months: [4, 5, 6, 7],
        },
        'toussaint': {
            name: 'Toussaint',
            months: [8, 9],
        },
        'noel': {
            name: 'Noël',
            months: [10, 11],
        }
    };    

    function getFirstSeason() {
        const now = new Date()
        for (const [key, season] of Object.entries(seasons)) {
           if (season.months.includes(now.getMonth())) {
            return key
           }
        }
        return 'hiver'
    }    

    const displayedSeasons = [
        ...Object.keys(seasons).slice(Object.keys(seasons).indexOf(getFirstSeason())),
        ...Object.keys(seasons).slice(0, Object.keys(seasons).indexOf(getFirstSeason()))
    ]

    return (
        <>
            <div id="sejour" className="mainSearch bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 mt-20">
                <div className="button-grid items-center">
                    <div className="px-30 lg:py-20 lg:px-0">
                        <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Rechercher</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Un lieu, des activités, ..."
                                    value={search['query'] ?? ''}
                                    onChange={(e) => {
                                        const {
                                            query,
                                            ...otherSearchParams
                                        } = search
                                        setSearch({
                                            ...otherSearchParams,                                        
                                            ...(e.target.value && { query: e.target.value})                                        
                                        })  
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key !== 'Enter') return
                                        handleSearch()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="searchMenu px-30 lg:py-20 lg:px-0 js-form-counters">
                        <Dropdown
                            title="Âge"
                            multiple={false}
                            search={false}
                            value={convertSearchParamToArray(search['age'] ?? "")}
                            options={Array.from({length: 18 - 4}, (_, i) => i + 4).reduce((options, age) => {
                                return {
                                    ...options,
                                    [age]: age + ' ans'
                                }
                            }, {})}
                            placeholder="Tous"
                            withLabel={true}
                            onChange={(value) => {
                                const {
                                    age,
                                    ...otherSearchParams
                                } = search
                                setSearch({
                                    ...otherSearchParams,                                        
                                    ...(value && {age: value})
                                })                                                    
                            }}
                        />
                    </div>
                    <div className="searchMenu px-30 lg:py-20 lg:px-0">
                        <Dropdown
                            title="Ville de départ"
                            multiple={{
                                maxDisplayedItems: 1,
                                overMaxDisplayedItemsLabel: " villes"
                            }}
                            search={true}
                            value={convertSearchParamToArray(search['departures'] ?? "")}
                            options={departures.reduce((options, city) => ({...options, [city]: city}), {})}
                            placeholder="Toutes les villes"
                            withLabel={true}
                            onChange={(value) => {
                                const {
                                    departures,
                                    ...otherSearchParams
                                } = search
                                setSearch({
                                    ...otherSearchParams,                                        
                                    ...(value.length > 0 && { departures: value.join(',')})                                        
                                })                                                    
                            }}
                        />
                    </div>
                    <div className="px-30 lg:py-20 lg:px-0">
                        <div>
                            <Dropdown
                                title="Dates et période"
                                multiple={false}
                                search={false}
                                value={search['season'] && search['year'] ? search['season'] + "-" + search['year'] : ""}
                                options={displayedSeasons.reduce((options, season) => {
                                    const now = new Date()
                                    const year = now.getFullYear() + (now.getMonth() > seasons[season].months.slice(-1)[0] ? 1 : 0)
                                    return {
                                        ...options,
                                        [season + '-' + year]: seasons[season].name + ' ' + year
                                    }
                                }, {})}
                                placeholder="Toutes"
                                withLabel={true}
                                onChange={(value) => {                                                                        
                                    const {
                                        year,
                                        season,
                                        ...otherSearchParams
                                    } = search
                                    setSearch({
                                        ...otherSearchParams,                                        
                                        ...(value.split('-').length === 2 && { season: value.split('-')[0], year: value.split('-')[1] })                                        
                                    })                                    
                                }}
                            />
                        </div>
                    </div>
                    <div className="button-item">
                        <button className="mainSearch__submit button col-12 rounded-full bg-primary text-white" onClick={() => {
                            handleSearch()
                        }}>
                            <span className="lancerrecherche mr-10 d-lg-none">Lancer la recherche</span>
                            <i className="icon-search text-20" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}