export const levels = {
    nursery_school: {
        name: 'Maternelle'
    },
    elementary_school: {
        name: 'Elémentaire'
    },
    middle_school: {
        name: 'Collège'
    },
    high_school: {
        name: 'Lycée'
    }
}

export const categories = {
    sea: {
        name: 'Mer',
        icon: 'fi fi-rr-crab'
    },
    mountain: {
        name: 'Montagne',
        icon: 'fi fi-rr-mountain'
    },
    nature: {
        name: 'Nature et environnement',
        icon: 'fi fi-rr-seedling'
    },
    heritage: {
        name: 'Histoire et patrimoine',
        icon: 'fi fi-rr-monument'
    }
}

export function prepareQuotation(quotation: any) {
    return {
        ...quotation,        
    }
}