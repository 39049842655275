import { searchHolidayStays, searchBafads, searchJobs, searchPressArticles, searchPosts, searchEducationCourses, searchSchoolActivities, searchSchoolThematics, searchPublications, searchAccomodations, searchTestimonies } from "@/api/helper";

export function toggleFilter(searchParams: URLSearchParams, filterName: string, filterValue: string) {
    const params = new URLSearchParams(searchParams)
    if (params.has(filterName) === false) {
        params.set(filterName, '')
    }
    let values = params.get(filterName)?.split(',').filter(e => /\w+/.test(e)) ?? []
    if (values.includes(filterValue)) {
        values.splice(values.indexOf(filterValue), 1)
    }
    else {
        values.push(filterValue)
    }
    if (values.length === 0) {
        params.delete(filterName)
    }
    else {
        params.set(filterName, values.join(','))
    }
    return params    
}

export function changeFilters(searchParams: URLSearchParams, filters): any {
    let params = new URLSearchParams(searchParams)
    Object.entries(filters).map(([name, value]) => {
        params.set(name, (value as string))
        if (!value) {
            params.delete(name)   
        }
    })  
    return params    
}

export function convertSearchParamToArray(searchParam: string) {
    return searchParam.split(',').filter(value => value)
}

export function getSearchFnByObject(object: string): Function {
    return {
        HolidayStay: searchHolidayStays,
        Bafad: searchBafads,
        EducationCourse: searchEducationCourses,
        SchoolActivity: searchSchoolActivities,
        SchoolThematic: searchSchoolThematics,
        SchoolStay: searchAccomodations,
        Job: searchJobs,
        Post: searchPosts,
        PressArticle: searchPressArticles,
        Publication: searchPublications,
        Accomodation: searchAccomodations,
        Testimony: searchTestimonies
    }[object] ?? null
}

export function convertsURLSearchParamsToObject(searchParams: URLSearchParams) {
    if (searchParams.size === 0) return {}
    return JSON.parse('{"' + searchParams.toString().replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
}