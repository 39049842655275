'use client'

import Dropdown from "@/components/form/Dropdown"
import { categories } from "@/services/educationCourse"
import { convertSearchParamToArray } from "@/services/search"
import { useSearchBar } from '@/components/search/useSearchBar'

type SearchBarProps = {
    search?: any,
    setSearch?: Function,
    handleSearch?: Function
}

export default function SearchBar({
    search,
    setSearch,
    handleSearch
}: SearchBarProps) {

    useSearchBar()
    
    return (
        <>
            <div id="educform" className="mainSearch bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 mt-20">
                <div className="button-grid items-center">
                    <div className="px-30 lg:py-20 lg:px-0">
                        <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Rechercher</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Une thématique, une intervention, ..."
                                    value={search['query'] ?? ''}
                                    onChange={(e) => {
                                        const {
                                            query,
                                            ...otherSearchParams
                                        } = search
                                        setSearch({
                                            ...otherSearchParams,                                        
                                            ...(e.target.value && { query: e.target.value})                                        
                                        })  
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key !== 'Enter') return
                                        handleSearch()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="searchMenu px-30 lg:py-20 lg:px-0">
                        <Dropdown
                            title="Thématique"
                            multiple={false}
                            search={false}
                            nullable={true}
                            value={convertSearchParamToArray(search['category'] ?? "")}
                            options={Object.fromEntries(Object.entries(categories).map(([keyCategory, category]) => {
                                return [
                                    keyCategory, category.name
                                ]
                            }))}
                            placeholder="Toutes les thématiques"                            
                            onChange={(value) => {
                                const {
                                    category,
                                    ...otherSearchParams
                                } = search
                                setSearch({
                                    ...otherSearchParams,                                        
                                    ...(value && { category: value })
                                })
                            }}
                            withLabel={true}
                        />
                    </div>
                    <div className="px-30 lg:py-20 lg:px-0">
                        <div>
                            <Dropdown
                                title="Public"
                                multiple={false}
                                search={false}
                                value={search['target'] ? search['target'] : ""}
                                options={{
                                    'children': 'Enfants / ados',
                                    'adults': 'Adultes'                                    
                                }}
                                placeholder="Tous"
                                onChange={(value) => {                                                                        
                                    const {
                                        target,
                                        ...otherSearchParams
                                    } = search
                                    setSearch({
                                        ...otherSearchParams,
                                        ...(value &&  { target: value })
                                    })                                    
                                }}
                                withLabel={true}
                            />
                        </div>
                    </div>
                    <div className="button-item">
                        <button className="mainSearch__submit button col-12 rounded-full bg-primary text-white" onClick={() => {
                            handleSearch()
                        }}>
                            <span className="lancerrecherche mr-10 d-lg-none">Lancer la recherche</span>
                            <i className="icon-search text-20" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}