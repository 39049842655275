'use client'

import Script from "next/script"
import { useEffect, useState } from "react"

type FacebookProps = {
    id: string,
    page: string
}

export default function Facebook({
    id,
    page
}: FacebookProps) {

    const [state, setState] = useState(null)

    useEffect(() => {
        async function load() {

        }
        load()
    }, [])


    return (
        <>
            <Script
                src='https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v21.0&appId=513266986155682'
                onLoad={() => setState(true)}
            />
            {state &&
                <>
                    {/*<div className="fb-post"
                    data-href="https://www.facebook.com/104904380894123/posts/100080167283443"
                    data-width="500">
                </div>*/}
                    <div id="fb-root"></div>
                    <div className="fb-page" data-href={page} data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="false" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/LesAroeven/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/LesAroeven/">Fédération des Aroéven</a></blockquote></div>
                    {/*<div className="fb-post"
                        data-href="https://www.facebook.com/20531316728/posts/10154009990506729/"
                        data-width="500"></div>*/}
                </>
            }

        </>
    )
}