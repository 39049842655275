import imgFacebook from '@/assets/img/rs/facebook.svg'
import imgInstagram from '@/assets/img/rs/instagram.svg'
import imgLinkedIn from '@/assets/img/rs/linkedin.svg'
import imgSnapchat from '@/assets/img/rs/snapchat.svg'
import imgTiktok from '@/assets/img/rs/tiktok.svg'
import imgWhatsapp from '@/assets/img/rs/whatsapp.svg'
import imgX from '@/assets/img/rs/x.svg'
import imgYoutube from '@/assets/img/rs/youtube.svg'

export const socialNetworks = {
    facebook: {
        name: 'Facebook',
        image: imgFacebook.src
    },
    instagram: {
        name: 'Instagram',
        image: imgInstagram.src
    },
    /*x: {
        name: 'X',
        image: imgX.src
    },*/
    linkedin: {
        name: 'LinkedIn',
        image: imgLinkedIn.src
    },
    youtube: {
        name: 'Youtube',
        image: imgYoutube.src
    },
    tiktok: {
        name: 'Tiktok',
        image: imgTiktok.src
    },
    snapchat: {
        name: 'SnapChat',
        image: imgSnapchat.src
    },
    whatsapp: {
        name: 'WhatsApp',
        image: imgWhatsapp.src
    }
}