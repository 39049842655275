export const categories = {
    wellbeing: {
        name: 'Climat scolaire et bien-être',
        color: 'citoyennete',
        band: 'theme2'
    },
    citizenship: {
        name: 'Education à la citoyenneté',
        color: 'bientre',
        band: 'theme1'
    },
    other: {
        name: 'Autre',
        color: 'bientre',
        band: 'theme1'
    }
}

export function prepareQuotation(quotation: any) {
    return {
        ...quotation,
        ...'educationCourses' in quotation && {
            educationCourses: quotation.educationCourses.map((educationCourse: EducationCourse) => educationCourse['@id'])
        }
    }
}