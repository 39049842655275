'use client'

import { useState } from "react"
import { getAsServerMediaUrl, getMediaUrl } from "@/services/pathname"
import avatarFemale from '@/assets/img/avatars/avatar-1.png'
import avatarMale from '@/assets/img/avatars/avatar-2.png'
import avatarNonBinary from '@/assets/img/avatars/avatar-3.png'
import Image from "next/image"

type StaffProps = {
    staff: Staff,
    active?: boolean
}

export default function Staff({
    staff,
    active = false
}: StaffProps) {

    const [hovered, setHovered] = useState<boolean>(false)

    return (
        <>
            <div {...staff.media && staff.canBeContacted === true && { onMouseEnter: () => setHovered(true), onMouseLeave: () => setHovered(false) }}>
                <div className={staff.media ? ((active || hovered) ? 'bubble-back actif' : 'bubble-back') : ''}>
                    <div className={"bubble team" + (staff.media ? ' photo' : '')}>
                        <div className="align-self-start mx-auto">
                            {staff.media ?
                                <>
                                    {/*<img src={getMediaUrl(staff.media.url)} alt={staff.firstName + ' ' + staff.lastName} />*/}
                                    <Image
                                        src={getAsServerMediaUrl(staff.media.url)}
                                        alt={staff.firstName + ' ' + staff.lastName}
                                        width={staff.media.metadata.width}
                                        height={staff.media.metadata.height}
                                        sizes="20vw"
                                        quality={90}
                                    />
                                </>
                                :
                                <img src={({
                                    male: avatarMale,
                                    female: avatarFemale,
                                    non_binary: avatarNonBinary
                                }[staff.gender] ?? avatarMale).src} />
                            }
                        </div>
                    </div>
                </div>
                <div className="nom align-self-end mx-auto">{staff.firstName} {staff.lastName}</div>
                <span className="info">{staff.jobTitle}</span>
            </div>
        </>
    )
}