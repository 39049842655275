'use client'

import { useEffect, useRef } from "react"

type LightboxProps = {
    selector: string
    openTriggerElementSelector?: string
}

export function Lightbox({
    selector,
    openTriggerElementSelector
}: LightboxProps) {

    const lightbox = useRef(null)

    useEffect(() => {
        const load = async () => {
            const GLightbox = (await import("glightbox")).default;
            lightbox.current = GLightbox({
                selector,
                touchNavigation: true,
                loop: false,
                autoplayVideos: true,
            });

            if (openTriggerElementSelector) {
                document.querySelector(openTriggerElementSelector)?.addEventListener('click', (e) => {
                    lightbox.current.open()                    
                })
            }

            // TOKNOW : pour éviter que le NextTopLoader s'active au clic sur les images
            document.querySelectorAll(selector).forEach(elem => {
                elem.addEventListener('click', (e) => {
                    console.log(e)
                    e.stopPropagation()
                })
            })
        }
        
        load()

        return () => {
            if (openTriggerElementSelector) {
                document.querySelector(openTriggerElementSelector)?.removeEventListener('click', (e) => {
                    lightbox.current.open()
                })
            }
        }
    }, [])

    return (
        <></>
    )
}