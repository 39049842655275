import imgTreeClimbing from '@/assets/img/icons/mousqueton.svg'
import imgClimbing from '@/assets/img/icons/escalade.svg'
import imgCanyoning from '@/assets/img/icons/canyoning.svg'
import imgRafting from '@/assets/img/icons/rafting.svg'
import imgSandYachting from '@/assets/img/icons/charavoile.svg'
import moment from 'moment'

export const environments = {
    mountain: {
        name: 'Montagne',
        icon: {
            type: 'font',
            name: 'fi fi-rr-mountains'
        }
    },
    sea: {
        name: 'Mer',
        icon: {
            type: 'font',
            name: 'fi fi-rr-sunrise'
        }
    },
    country: {
        name: 'Campagne',
        icon: {
            type: 'font',
            name: 'fi fi-rr-daisy-alt'
        }
    },
    foreign: {
        name: 'Etranger',
        icon: {
            type: 'font',
            name: 'fi fi-rr-world'
        }
    },
    urban: {
        name: 'Urbain',
        icon: {
            type: 'font',
            name: 'fi fi-rr-house-building'
        }
    }
}

export const thematics = {
    roaming: {
        name: 'En itinérance',
        icon: {
            type: 'font',
            name: 'fi fi-rr-track',
        }
    },
    sports: {
        name: 'Séjour sportif',
        icon: {
            type: 'font',
            name: 'fi fi-rr-volleyball',
        }
    },
    cultural: {
        name: 'Séjour culturel',
        icon: {
            type: 'font',
            name: 'fi fi-rr-archway',
        }
    },
    snow: {
        name: 'Séjour à la neige',
        icon: {
            type: 'font',
            name: 'fi fi-rr-snowflake',
        }
    },
    language: {
        name: 'Séjour linguistique',
        icon: {
            type: 'font',
            name: 'fi fi-rr-messages',
        }
    },
    artistic: {
        name: 'Séjour artistique',
        icon: {
            type: 'font',
            name: 'fi fi-rr-palette',
        }
    },
    nature: {
        name: 'Séjour nature',
        icon: {
            type: 'font',
            name: 'fi fi-rr-grass',
        }
    },
    multi_activity: {
        name: 'Séjour multi-activités',
        icon: {
            type: 'font',
            name: 'fi fi-rr-multiple-alt',
        }
    },
    discovery: {
        name: 'Séjour découverte',
        icon: {
            type: 'font',
            name: 'fi fi-rr-binoculars',
        }
    },
    nautical: {
        name: 'Séjour nautique',
        icon: {
            type: 'font',
            name: 'fi fi-rr-water',
        }
    },
    scientific: {
        name: 'Séjour scientifique',
        icon: {
            type: 'font',
            name: 'fi fi-rr-microscope',
        }
    }
}

export const activities = {
    bike: {
        name: 'VTT',
        icon: {
            type: 'font',
            name: 'fi fi-rr-biking-mountain',
        }
    },
    archery: {
        name: 'Tir à l\'arc',
        icon: {
            type: 'font',
            name: 'fi fi-rr-bow-arrow',
        },
    },
    tree_climbing: {
        name: 'Accrobranche',
        icon: {
            type: 'image',
            image: imgTreeClimbing
        }
    },
    hiking: {
        name: 'Randonnée',
        icon: {
            type: 'font',
            name: 'fi fi-rr-hiking',
        },
    },
    surf: {
        name: 'Surf',
        icon: {
            type: 'font',
            name: 'fi fi-rr-surfing'
        }
    },
    riding: {
        name: 'Equitation',
        icon: {
            type: 'font',
            name: 'fi fi-rr-horse-head'
        }
    },
    swimming: {
        name: 'Baignade',
        icon: {
            type: 'font',
            name: 'fi fi-rr-swimmer'
        }
    },
    skiing: {
        name: 'Ski alpin',
        icon: {
            type: 'font',
            name: 'fi fi-rr-skiing'
        }
    },
    snow_games: {
        name: 'Jeux de neige',
        icon: {
            type: 'font',
            name: 'fi fi-rr-ball-pile'
        }
    },
    snowboarding: {
        name: 'Snowboard',
        icon: {
            type: 'font',
            name: 'fi fi-rr-snowboarding'
        }
    },    
    bivouac: {
        name: 'Bivouac',
        icon: {
            type: 'font',
            name: 'fi fi-rr-tents'
        }
    },
    theme_park: {
        name: 'Parcs d\'attraction',
        icon: {
            type: 'font',
            name: 'fi fi-rr-ferris-wheel'
        }
    },
    sledding: {
        name: 'Luge',
        icon: {
            type: 'font',
            name: 'fi fi-rr-sledding'
        }
    },
    cultural_visits: {
        name: 'Visites culturelles',
        icon: {
            type: 'font',
            name: 'fi fi-rr-landmark-alt'
        }
    },
    reading: {
        name: 'Lecture',
        icon: {
            type: 'font',
            name: 'fi fi-rr-books'
        }
    },
    climbing: {
        name: 'Escalade',
        icon: {
            type: 'image',
            image: imgClimbing
        }
    },
    caving: {
        name: 'Spéléologie',
        icon: {
            type: 'font',
            name: 'fi fi-rr-hard-hat'
        }
    },
    canyoning: {
        name: 'Canyoning',
        icon: {
            type: 'image',
            image: imgCanyoning
        }
    },
    rafting: {
        name: 'Rafting',
        icon: {
            type: 'image',
            image: imgRafting
        }
    },
    games: {
        name: 'Jeux / Grands jeux',
        icon: {
            type: 'font',
            name: 'fi fi-rr-dice-alt'
        }
    },
    wakes: {
        name: 'Veillées',
        icon: {
            type: 'font',
            name: 'fi fi-rr-campfire'
        }
    },
    buoying: {
        name: 'Bouée tractée',
        icon: {
            type: 'font',
            name: 'fi fi-rr-life-ring'
        }
    },
    canoeing: {
        name: 'Canoë - Kayak',
        icon: {
            type: 'font',
            name: 'fi fi-rr-kayak'
        }
    },
    diving: {
        name: 'Plongée',
        icon: {
            type: 'font',
            name: 'fi fi-rr-mask-snorkel'
        }
    },
    photo: {
        name: 'Photo / Vidéo',
        icon: {
            type: 'font',
            name: 'fi fi-rr-camera'
        }
    },
    sand_yachting: {
        name: 'Char à voile',
        icon: {
            type: 'image',
            image: imgSandYachting
        }
    },
    escape_game: {
        name: 'Escape game / Orientation',
        icon: {
            type: 'font',
            name: 'fi fi-rr-map'
        }
    },
    zoo: {
        name: 'Zoo',
        icon: {
            type: 'font',
            name: 'fi fi-rr-paw-claws'
        }
    },
    diy: {
        name: 'Bricolage',
        icon: {
            type: 'font',
            name: 'fi fi-rr-hammer-crash'
        }
    },
    cooking: {
        name: 'Cuisine',
        icon: {
            type: 'font',
            name: 'fi fi-rr-hat-chef'
        }
    },
    beach: {
        name: 'Plage',
        icon: {
            type: 'font',
            name: 'fi fi-rr-umbrella-beach'
        }
    }
}

export const transports = {
    plane: {
        name: 'Avion',
        icon: 'fi fi-rr-plane'
    },
    boat: {
        name: 'Bateau',
        icon: 'fi fi-rr-ship'
    },
    train: {
        name: 'Train',
        icon: 'fi fi-rr-train'
    },
    bike: {
        name: 'Vélo',
        icon: 'fi fi-rr-bike'
    },
    bus: {
        name: 'Car',
        icon: 'fi fi-rr-bus'
    },
    minibus: {
        name: 'Minibus',
        icon: 'fi fi-rr-school-bus'
    }
}

export function getNextSessions(holidayStay: HolidayStay): HolidayStaySession[] {
    return holidayStay.sessions.filter(session => moment(session.startDate) > moment() && session.published === true)
}

export function sortDepartures(departures: HolidayStayDeparture[]): HolidayStayDeparture[] {
    const sortedDepartures = [...departures]
    sortedDepartures.sort((a: HolidayStayDeparture, b: HolidayStayDeparture) => {        
        return a.price === b.price ? (a.city < b.city ? -1 : 1) : (a.price < b.price ? -1 : 1)
    })
    return sortedDepartures
}

export function sortBacks(backs: HolidayStayBack[]): HolidayStayBack[] {
    const sortedBacks = [...backs]
    sortedBacks.sort((a: HolidayStayBack, b: HolidayStayBack) => {        
        return a.city < b.city ? -1 : 1
    })
    return sortedBacks
}