"use client"

import { Collapse } from "@/components/ui/Collapse"
import { getName } from "@/services/aroeven"
import { parsePhone } from "@/services/text"
import { useRouter } from "next/navigation"
import React, { ReactNode, useEffect, useId, useRef, useState } from "react"
import { socialNetworks } from "@/services/socialNetwork"

type AroevensProps = {
    aroevens: Aroeven[],
    mode?: string
}

export function Aroevens({
    aroevens,
    mode = 'home'
}: AroevensProps) {

    const ref = useRef<HTMLDivElement>()

    const { push } = useRouter()

    const accordionId = useId()

    const disabled = ['montpellier']

    const [selectedAroeven, setSelectedAroeven] = useState<Aroeven>(null)

    const federation = aroevens.filter(aroeven => aroeven.federation === true)[0] ?? null

    useEffect(() => {
        /*ref.current.querySelectorAll('g polygon[data-aroeven], g path[data-aroeven], .libelle-region a').forEach((el) => {
            el.addEventListener('mouseenter', (e) => addHighlight(el.getAttribute('data-aroeven')))
            el.addEventListener('mouseleave', (e) => removeHighlight(el.getAttribute('data-aroeven')))
        })*/

        /*ref.current.querySelectorAll('svg [data-aroeven]').forEach((svgPart) => {
            svgPart.addEventListener('click', (e) => {
                if (disabled.includes(svgPart.getAttribute('data-aroeven')) === true) return
                (document.querySelector('.libelle-region a[data-aroeven="' + svgPart.getAttribute('data-aroeven') + '"]') as HTMLElement).click()
            })
        })*/

        /*ref.current.querySelectorAll('svg [data-aroeven]').forEach((svgPart) => {
            svgPart.addEventListener('click', () => {
                console.log('selected', aroevens.filter(aroeven => aroeven.slug === svgPart.getAttribute('data-aroeven'))[0] ?? null)
                setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === svgPart.getAttribute('data-aroeven'))[0] ?? null)
            })
        })

        return (() => {
            ref.current?.querySelectorAll('g polygon[data-aroeven], g path[data-aroeven], .libelle-region a, .libelle-region a').forEach((el) => {
                //el.removeEventListener('mouseenter', (e) => addHighlight(el.getAttribute('data-aroeven')))
                //el.removeEventListener('mouseleave', (e) => removeHighlight(el.getAttribute('data-aroeven')))
            })
        })*/
    }, [])

    /*useEffect(() => {
        if (selectedAroeven) {
            addHighlight(selectedAroeven.slug)
            return
        }
        ref.current.querySelectorAll('svg [data-aroeven]').forEach(svgPart => removeHighlight(svgPart['data-aroeven']))
    }, [selectedAroeven])*/

    /*function addHighlight(aroeven: string) {
        ref.current.querySelectorAll('[data-aroeven="' + aroeven + '"]').forEach((hoverEl) => {
            console.log('hoverel', hoverEl)
            hoverEl.classList.add('surbrillance')
        })
    }*/

    /*function removeHighlight(aroeven: string) {
        console.log('remove', selectedAroeven?.slug, aroeven)
        if (selectedAroeven && selectedAroeven.slug === aroeven) return
        ref.current.querySelectorAll('[data-aroeven="' + aroeven + '"]').forEach((hoverEl) => {
            hoverEl.classList.remove('surbrillance')
        })
    }*/

    return (
        <>
            <div className="parent flex-column flex-lg-row mt-30" {...mode === 'home' && { 'data-anim-child': "slide-up delay-2" }}>
                <div className="child">
                    <div className="cartefrance">
                        <svg style={{ width: '100%' }} id="uuid-19daee4e-9dac-4e3a-826a-6800606f0e2f" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 727.98 684.18">
                            <defs>
                                <style>{
                                    `.uuid-98c7d0ab-1e4a-4be2-9733-59ccba8dc78b {
                                    isolation: isolate;
                                    opacity: 1;
                                }

                                .uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92 {
                                    fill: #5f9c99;
                                    cursor: pointer;
                                }

                                .uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92.disabled {
                                    fill: #aec5c2;
                                    cursor: default;
                                }

                                .uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92:not(.disabled):hover, .uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92:not(.disabled).surbrillance {
                                    fill: #1b706a;
                                }

                                .uuid-21d81bae-9203-469a-a069-8c7c4d023398 {
                                    fill: #e3eae7;
                                }`
                                }
                                </style>
                            </defs>
                            <g id="uuid-2f7222f0-db8e-44a0-941c-940957eca459" data-name="SMc5aZ" className="uuid-98c7d0ab-1e4a-4be2-9733-59ccba8dc78b">
                                <path className="uuid-21d81bae-9203-469a-a069-8c7c4d023398" d="M205.98,103.09c3.3,8.78,6.61,17.56,9.91,26.33,19.02.98,38.04,1.95,57.06,2.93,3.53-9.04,7.05-18.09,10.58-27.13,11.67-4.14,23.34-8.28,35.02-12.43,13.82-7.54,27.64-15.08,41.46-22.63l1.78-58.36L413.03,0c32.07,26.07,64.14,52.14,96.22,78.22,35.84,15.03,71.68,30.05,107.51,45.08,36.1,10.58,72.2,21.16,108.3,31.74-9.43,19.23-18.87,38.47-28.3,57.7-2.31,21.5-4.61,43.01-6.92,64.51-7.03,5.35-14.05,10.7-21.08,16.05-10.76,9.27-21.52,18.55-32.28,27.82-5.68,19.43-11.36,38.86-17.04,58.3,9.58-5.55,19.16-11.11,28.75-16.66,4.16-1.21,8.33-2.42,12.49-3.63,6.49,28.34,12.97,56.69,19.46,85.03-4.53,5.06-9.07,10.13-13.6,15.19,7.3,19.42,14.6,38.83,21.9,58.25,13.18,5.7,26.37,11.4,39.55,17.1-26.61,29.41-53.23,58.82-79.84,88.24-42.98-11.82-85.95-23.64-128.93-35.46-20.88,7.04-41.76,14.07-62.64,21.11-4.03,16.82-8.07,33.65-12.1,50.47-.34,8.37-.67,16.75-1.01,25.12-46.79-11.1-93.57-22.21-140.36-33.31-23.09-3.03-69.27-9.09-69.27-9.09,0,0-54.97-25.6-82.46-38.41,10.53-25.57,21.07-51.14,31.6-76.71,3.66-33.41,7.32-66.82,10.97-100.22,6.55,6.21,13.09,12.42,19.64,18.64.39.08.69.03.91-.1h0s0,0,0,0,.29-3.35.3-3.44c.16-1.94-3.78-14.74-16.89-51.15-14.78-14.19-29.57-28.38-44.35-42.56-8.85-21.59-17.7-43.18-26.55-64.77-36.34-15.16-72.67-30.32-109.01-45.47C11.99,219.06,6,200.55,0,182.03c34.14-5.34,68.28-10.68,102.43-16.01,8.61,4.49,17.23,8.99,25.84,13.48,19.59,2.63,39.17,5.26,58.76,7.89-1.14-14.39-2.29-28.79-3.43-43.18-4.76-13.71-9.52-27.42-14.28-41.12h36.67Z" />
                            </g>
                            <g>
                                <polygon data-aroeven='orleans' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'orleans' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'orleans')[0] ?? null)} points="429.77 258.61 438.23 237.16 432.55 231.89 415.49 235.3 405.66 224.88 381.67 219.78 354.12 175.31 347.48 184.38 319.93 191.77 326.1 199.48 314.14 223.4 317.48 248.86 284.15 272.39 273.42 305 303.53 315.87 330.44 364.11 392.45 360.81 434.85 332.57 429.77 258.61" />
                                <path data-aroeven='rennes' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'rennes' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'rennes')[0] ?? null)} d="M209.83,238.23v-42.37l-23.54-8.57c-19.34-2.6-38.68-5.2-58.02-7.79-8.61-4.49-17.23-8.99-25.84-13.48-34.14,5.34-68.28,10.68-102.43,16.01,6,18.51,11.99,37.03,17.99,55.54,35.52,14.82,71.04,29.63,106.55,44.45l64.66-31.72,20.63-12.07Z" />
                                <polygon data-aroeven='lyon' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'lyon' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'lyon')[0] ?? null)} points="551.82 359.54 543.32 372.67 494.11 376.51 486 419.29 502.09 443.07 528.6 444.91 567.94 427.09 562.23 392.81 589.22 400.75 589.91 371.69 574.9 359.54 551.82 359.54" />
                                <path data-aroeven='nantes' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'nantes' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'nantes')[0] ?? null)} d="M211.68,316.02l57.7-11.54,11.38-34.6,32.44-22.9-3-22.86-22.12-16.96h-53.9l-20.35-9.55v42.91l-22.8,13.33-63.27,31.04c8.6,20.97,17.19,41.94,25.79,62.92,10.78,10.34,21.55,20.68,32.33,31.03l45.29-17.19-19.48-45.63Z" />
                                <path data-aroeven='reims' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'reims' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'reims')[0] ?? null)} d="M461.85,210.72l30.49,34.95,43.79-11.37,16.51,27.95h22.17l20.57-19.15-55.36-64.84,15.97-80.44c-14.97-6.28-29.94-12.55-44.91-18.83l-45.47,93.31-3.77,38.43Z" />
                                <path data-aroeven='rouen' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'rouen' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'rouen')[0] ?? null)} d="M317.2,188.36l27.88-7.48,24.85-33.89v-42.97l-21.41-27.58c-9.99,5.45-19.99,10.91-29.98,16.36-11.67,4.14-23.34,8.28-35.02,12.43-3.23,8.3-6.47,16.6-9.7,24.89l23.53,33.43,19.85,24.81Z" />
                                <polygon data-aroeven='dijon' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'dijon' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'dijon')[0] ?? null)} points="534.23 238.93 490.96 250.17 459.44 214.05 436.35 229.96 442.95 236.08 433.83 259.23 438.83 332.12 465.96 341.26 477.51 339.98 494.43 362.11 494.43 372.48 541.04 368.84 549.65 355.54 573.32 355.54 568.15 317.93 568.18 317.69 573.4 266.25 550.37 266.25 534.23 238.93" />
                                <path data-aroeven='nancy' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'nancy' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'nancy')[0] ?? null)} d="M598.88,241.03l26.64-6.5,26.49,10.7,19.21-70.94-15.83-8.45,6.09-12.53,26.2,2.31,3.31-10.57c-24.74-7.25-49.49-14.5-74.23-21.75-19-7.97-38-15.93-56.99-23.9l-15.43,77.74,54.54,63.89Z" />
                                <path data-aroeven='lille' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'lille' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'lille')[0] ?? null)} d="M373.93,145.85l71.12,7.65,17.94,15.07,44.66-91.64c-31.54-25.64-63.08-51.28-94.62-76.92l-51.24,11.81c-.59,19.45-1.18,38.91-1.78,58.36-2.64,1.44-5.28,2.88-7.92,4.32l21.85,28.15v43.2Z" />
                                <path data-aroeven='caen' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'caen' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'caen')[0] ?? null)} d="M213.83,193.2l21.24,9.97h54.37l21.94,16.82,10-20.01-27.26-34.08-23.7-33.68c-18.17-.93-36.35-1.86-54.52-2.8l-9.91-26.33h-36.67c4.76,13.71,9.52,27.42,14.28,41.12,1.03,12.99,2.07,25.98,3.1,38.97l27.14,9.88v.13Z" />
                                <path data-aroeven='strasbourg' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'strasbourg' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'strasbourg')[0] ?? null)} d="M663.87,157.53l-3.21,6.59,15.24,8.14-20.29,74.93,34.47,27.7c2.22-20.72,4.44-41.43,6.67-62.15,9.43-19.23,18.87-38.47,28.3-57.7-10.07-2.95-20.15-5.9-30.22-8.86l-4.29,13.71-26.66-2.35Z" />
                                <polygon data-aroeven='limoges' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'limoges' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'limoges')[0] ?? null)} points="404.63 440.94 401.9 408.86 413.61 397.15 392.07 364.84 330.34 368.12 298.62 410.72 338.42 443.77 349.05 469.61 379.34 474.65 404.63 440.94" />
                                <path data-aroeven='montpellier' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'montpellier' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'montpellier')[0] ?? null)} d="M541.06,528.86l-36.81-5.48-13.36-31.44-24.84-12.07-26.5,22.65,28.8,45.25-52.75,47.12h-42.54l-6.78,11,23.35,46.85-22.81,13.24,76.64,18.19c.34-8.37.67-16.75,1.01-25.12,4.03-16.82,8.07-33.65,12.1-50.47,20.69-6.97,41.38-13.94,62.06-20.92l34.86-41.7-12.44-17.12Z" />
                                <polygon data-aroeven='clermont' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'clermont' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'clermont')[0] ?? null)} points="491.9 487.99 524.91 448.66 499.87 446.92 481.76 420.17 490.43 374.45 490.43 363.46 475.7 344.2 465.52 345.33 465.1 345.19 437.21 335.8 395.88 363.34 418.76 397.66 406.04 410.38 408.74 442.12 382.55 477.04 393.82 499.58 423.5 477.33 437.37 499.12 465.44 475.13 491.9 487.99" />
                                <polygon data-aroeven='paris' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'paris' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'paris')[0] ?? null)} points="435.05 188.95 429.19 173.36 406.84 173.36 383.14 177.18 383.14 182.8 405 198.67 438.52 195.82 435.05 188.95" />
                                <path data-aroeven='poitiers' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'poitiers' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'poitiers')[0] ?? null)} d="M257.36,459.43l69.56-93.41-26.16-46.89-30-10.84-53.47,10.69,19.19,44.93-47.41,18c2.94,2.82,5.89,5.65,8.83,8.47,12.77,35.48,16.84,48.53,16.89,50.97l.05-.12,42.52,18.2Z" />
                                <path data-aroeven='grenoble' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'grenoble' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'grenoble')[0] ?? null)} d="M593.09,406.06l-25.87-7.61,5.17,31.02-42.19,19.1-35.46,42.25,12.3,28.93,35.38,5.27,52.9,5.14-4.86-15.28,44.73-51.91.7-.12,32.81-5.92c3.81-4.25,7.62-8.51,11.43-12.76-6.48-28.34-12.97-56.69-19.46-85.03-4.16,1.21-8.33,2.42-12.49,3.63-9.58,5.55-19.16,11.11-28.75,16.66,1.92-6.58,3.85-13.16,5.77-19.74l-31.3,12.44-.81,33.93Z" />
                                <path data-aroeven='paca' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'paca' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'paca')[0] ?? null)} d="M727.98,534.71c-13.18-5.7-26.37-11.4-39.55-17.1-7.06-18.78-14.12-37.56-21.19-56.34l-29.94,5.4-42.34,49.15,6.02,18.93-54.57-5.31,12.15,16.73-35.45,42.41c41.68,11.46,83.35,22.92,125.03,34.38,26.61-29.41,53.23-58.82,79.84-88.24Z" />
                                <polygon data-aroeven='versailles' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'versailles' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'versailles')[0] ?? null)} points="396.95 218.94 402.1 201.51 379.14 184.83 379.14 173.77 405.18 169.6 410.74 153.83 372.87 149.75 356.67 171.84 384.18 216.22 396.95 218.94" />
                                <path data-aroeven='besancon' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'besancon' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'besancon')[0] ?? null)} d="M625.21,238.72l-26.08,6.37-21.61,20.12-5.34,52.65,5.3,38.62,14.8,11.98,34.35-13.65,9.85-33.69c10.76-9.27,21.52-18.55,32.28-27.82,6.48-4.94,12.96-9.87,19.44-14.81l-35.87-28.82-27.12-10.95Z" />
                                <polygon data-aroeven='creteil' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'creteil' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'creteil')[0] ?? null)} points="431.96 169.36 438.76 187.44 444.77 199.3 405.95 202.6 400.88 219.77 407.71 221.22 416.88 230.95 432.36 227.85 457.88 210.27 461.57 172.6 443.41 157.34 414.83 154.27 409.51 169.36 431.96 169.36" />
                                <path data-aroeven='toulouse' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'toulouse' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'toulouse')[0] ?? null)} d="M361.7,605.7l9.11-14.79h43.25l49.08-43.84-40.69-63.95-30.06,22.54-13.51-27.03-1.68-.28-28.53-4.75-40.46,70.14-60.84,14.66,10.75,40.86-22.18,42.8c9.29,1.22,47.01,6.17,67.16,8.82,19.35,4.59,38.71,9.19,58.06,13.78l23.21-13.47-22.67-45.48Z" />
                                <path data-aroeven='bordeaux' className={"uuid-49a0ec8a-2e9c-4f2e-aa04-dd0abd6fbc92" + (selectedAroeven && selectedAroeven.slug === 'bordeaux' ? ' surbrillance' : '')} onClick={() => setSelectedAroeven(aroevens.filter(aroeven => aroeven.slug === 'bordeaux')[0] ?? null)} d="M242.45,555.46l63.14-15.21,39.81-69-10.3-25.03-38.87-32.29-37.55,50.42-45.03-19.27s-.05,0-.07,0c-.02-.02-.04-.03-.05-.05l-.26-.11.04-.1c-6.46-6.13-12.91-12.25-19.37-18.38-3.66,33.41-7.32,66.82-10.97,100.22-10.53,25.57-21.07,51.14-31.6,76.71,24.53,11.42,70.93,33.04,80.65,37.56l21.84-42.15-11.4-43.32Z" />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="child libelle-region">
                    <div className="parent">
                        <div className="child">
                            <ul>
                                <li>
                                    <h5 className="selection">Sélectionnez une zone géographique pour visualiser l'Aroéven en région et accéder à son site web.</h5>
                                </li>
                                {selectedAroeven &&
                                    <>
                                        {disabled.includes(selectedAroeven.slug) === false ?
                                            <li>
                                                <h5>{getName(selectedAroeven)}</h5>
                                                {selectedAroeven.address &&
                                                    <a target='_blank' href={'https://www.google.com/maps?q=' + encodeURIComponent(selectedAroeven.address.address1 + ', ' + selectedAroeven.address.postCode + ' ' + selectedAroeven.address.city)}>
                                                        <span className="aroeven-contact">
                                                            {selectedAroeven.address.address1}<br />
                                                            {selectedAroeven.address.address2 && <>{selectedAroeven.address.address2}<br /></>}
                                                            {selectedAroeven.address.postCode} {selectedAroeven.address.city}
                                                        </span>
                                                    </a>
                                                }
                                                {selectedAroeven.showEmail && selectedAroeven.email && <span className="aroeven-contact text-primary-3">{selectedAroeven.email}</span>}
                                                {selectedAroeven.phone && <span className="aroeven-contact text-primary-3">Téléphone : <a href={'tel:' + selectedAroeven.phone.replace(/[^0-9+]/g, '')}>{parsePhone(selectedAroeven.phone)}</a></span>}
                                                {selectedAroeven.url && <a href={selectedAroeven.url} className="button-primary web d-block" target="_blank">
                                                    <i className="fi fi-rr-arrow-up-right-from-square" /> Site web
                                                </a>}
                                                {selectedAroeven.socialNetworks.length > 0 &&
                                                    <>
                                                        {selectedAroeven.socialNetworks.map((socialNetwork, indexSocialNetwork) => {
                                                            return (
                                                                <React.Fragment key={'socialNetwork-' + indexSocialNetwork}>
                                                                    <a className="social" href={socialNetwork.url} target="_blank"><img className="mr-1 me-1" style={{ height: 36 }} src={socialNetworks[socialNetwork.network]?.image ?? '#'} alt={socialNetworks[socialNetwork.network]?.name} /></a>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </li>
                                            :
                                            <>
                                                {federation &&
                                                    <li>
                                                        <h5>{getName(federation)}</h5>
                                                        {federation.address &&
                                                            <a target='_blank' href={'https://www.google.com/maps?q=' + encodeURIComponent(federation.address.address1 + ', ' + federation.address.postCode + ' ' + federation.address.city)}>
                                                                <span className="aroeven-contact">
                                                                    {federation.address.address1}<br />
                                                                    {federation.address.address2 && <>{federation.address.address2}<br /></>}
                                                                    {federation.address.postCode} {federation.address.city}
                                                                </span>
                                                            </a>
                                                        }
                                                        {federation.showEmail && federation.email && <span className="aroeven-contact text-primary-3">{federation.email}</span>}
                                                        {federation.phone && <span className="aroeven-contact text-primary-3">Téléphone : <a href={'tel:' + federation.phone.replace(/[^0-9+]/g, '')}>{parsePhone(federation.phone)}</a></span>}
                                                        {federation.url && <a href={federation.url} className="button-primary web d-block" target="_blank">
                                                            <i className="fi fi-rr-arrow-up-right-from-square" /> Site web
                                                        </a>}
                                                    </li>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </ul>
                        </div>

                        {/*<div className="child">
                        <ul>
                            {aroevens.filter(aroeven => aroeven.federation !== true && disabled.includes(aroeven.slug) === false).sort((a, b) => a.name < b.name ? -1 : 1).slice(0, Math.ceil(aroevens.length / 2)).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <li><a href={disabled.includes(aroeven.slug) ? '#' : aroeven.url} data-aroeven={aroeven.slug}>Aroéven {aroeven.name}</a></li>
                                    </React.Fragment>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="child">
                        <ul>
                            {aroevens.filter(aroeven => aroeven.federation !== true && disabled.includes(aroeven.slug) === false).sort((a, b) => a.name < b.name ? -1 : 1).slice(Math.ceil(aroevens.length / 2)).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <li><a href={aroeven.url} data-aroeven={aroeven.slug} onClick={(e) => {
                                            if (disabled.includes(aroeven.slug) === true) {
                                                e.preventDefault()
                                            }
                                        }}>Aroéven {aroeven.name}</a></li>
                                    </React.Fragment>
                                )
                            })}
                        </ul>
                    </div>*/}
                    </div>
                </div>
            </div>
            {mode === 'full' &&
                <div className="accordion accordion-flush" id={accordionId}>
                    <div className="row liste-aroevens mt-40">
                        <div className="col-md-6 col-lg-4">
                            <h3>Auvergne - Rhône-Alpes</h3>
                            {['clermont', 'grenoble', 'lyon'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Bourgogne - Franche-Comté</h3>
                            {['besancon', 'dijon'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Bretagne</h3>
                            {['rennes'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Centre Val de Loire</h3>
                            {['orleans'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <h3>Grand-Est</h3>
                            {['nancy', 'reims', 'strasbourg'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Hauts-de-France</h3>
                            {['lille'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Île-de-France</h3>
                            {['creteil', 'paris', 'versailles'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Normandie</h3>
                            {['caen', 'rouen'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <h3>Nouvelle-Aquitaine</h3>
                            {['bordeaux', 'limoges', 'poitiers'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Occitanie</h3>
                            {['toulouse'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Provence-Alpes-Côte d'Azur</h3>
                            {['paca'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                            <h3>Pays de la Loire</h3>
                            {['nantes'].map(slug => aroevens.filter(aroeven => aroeven.slug === slug)[0] ?? null).filter(a => a).map((aroeven, indexAroeven) => {
                                return (
                                    <React.Fragment key={'aroeven-' + indexAroeven}>
                                        <Aroeven aroeven={aroeven} parent={accordionId} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

type AroevenProps = {
    aroeven: Aroeven,
    parent: string
}

function Aroeven({
    aroeven,
    parent
}: AroevenProps) {

    const id = useId()

    return (
        <>
            <h5 className="selection">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#' + id} aria-expanded="false" aria-controls="flush-collapseOne">{getName(aroeven)}</button>
            </h5>
            <Collapse
                id={id}
                className="accordion-collapse collapse"
                parent={parent}
            >
                <div className="mx-1">
                    {aroeven.address &&
                        <a target='_blank' href={'https://www.google.com/maps?q=' + encodeURIComponent(aroeven.address.address1 + ', ' + aroeven.address.postCode + ' ' + aroeven.address.city)}>
                            <span className="aroeven-contact">
                                {aroeven.address.address1}<br />
                                {aroeven.address.address2 && <>{aroeven.address.address2}<br /></>}
                                {aroeven.address.postCode} {aroeven.address.city}
                            </span>
                        </a>
                    }
                    {aroeven.showEmail && aroeven.email && <span className="aroeven-contact text-primary-3">{aroeven.email}</span>}
                    {aroeven.phone && <span className="aroeven-contact text-primary-3">Téléphone : <a href={'tel:' + aroeven.phone.replace(/[^0-9+]/g, '')}>{parsePhone(aroeven.phone)}</a></span>}
                    {aroeven.url && <a href={aroeven.url} className="button-primary web d-block" target="_blank">
                        <i className="fi fi-rr-arrow-up-right-from-square" /> Site web
                    </a>}
                    {aroeven.socialNetworks.length > 0 &&
                        <>
                            {aroeven.socialNetworks.map((socialNetwork, indexSocialNetwork) => {
                                return (
                                    <React.Fragment key={'socialNetwork-' + indexSocialNetwork}>
                                        <a className="social" href={socialNetwork.url} target="_blank"><img className="mr-1 me-1" style={{ height: 36 }} src={socialNetworks[socialNetwork.network]?.image ?? '#'} alt={socialNetworks[socialNetwork.network]?.name} /></a>
                                    </React.Fragment>
                                )
                            })}
                        </>
                    }
                </div>
            </Collapse>
        </>
    )
}