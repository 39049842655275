'use client'

import React, { useEffect, useId, useRef } from "react"
//import bootstrap from 'bootstrap/dist/js/bootstrap'

export default function Accordions({
    title,
    subTitle,
    accordions
}: SectionAccordions) {

    const ref = useRef<HTMLDivElement>(null)
    const id = useId()
    
    useEffect(() => {
        
        const load = async () => {
            const bootstrap = (await import('bootstrap/dist/js/bootstrap')).default;
            ref.current.querySelectorAll('.collapse').forEach((collapse) => {
                new bootstrap.Collapse(collapse, {
                    toggle: false
                })
            })
        }
        
        load()
    }, [])

    return (
        <section className="faq">
            <div className="container" ref={ref}>
                {title && <h2>{title}</h2>}
                {subTitle && <h4>Sous-titre ou description</h4>}
                <div className="accordion accordion-flush mt-40" id={id}>
                    {accordions.map((accordion, indexAccordion) => {
                        return (
                            <React.Fragment key={"accordion-" + indexAccordion}>
                                <Accordion accordion={accordion} parent={id} opened={indexAccordion === 0} />
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

type AccordionProps = {
    accordion: SectionAccordion,
    parent: string,
    opened: boolean
}

function Accordion({
    accordion,
    parent,
    opened
}: AccordionProps) {

    const id = useId()
    const { title, text } = accordion

    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id={id + '-heading'}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + id + '-collapse'} aria-expanded={opened ? "true" : "false"} aria-controls={parent}>
                        {title}
                    </button>
                </h2>
                <div id={id + '-collapse'} className={"accordion-collapse collapse " + (opened ? "show" : "")} aria-labelledby={id + '-heading'}>
                    <div className="accordion-body from-wysiwyg" dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    )
}