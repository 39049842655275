export const levels = {
    bafa_initial: {
        name: 'Bafa - Formation générale',
        short: 'Formation générale',
        active: true,        
        step: 1,
        type: 'bafa',
        searchable: true
    },
    bafa_internship: {
        name: 'Bafa - Stage pratique',
        short: 'Stage pratique',
        active: false,        
        step: 2,
        type: 'bafa',
        searchable: false
    },
    bafa_indepth: {
        name: 'Bafa - Approfondissement',
        short: 'Approfondissement',
        active: true,
        step: 3,
        type: 'bafa',
        searchable: true
    },
    bafa_qualification: {
        name: 'Bafa - Qualification',
        short: 'Qualification',
        active: true,        
        step: 3,
        type: 'bafa',
        searchable: true
    },
    bafa_renewal: {
        name: 'Bafa - Renouvellement Qualif. Surveillant Baignade',
        short: 'Renouvellement Qualif. Surv. Baign.',
        active: true,
        step: 4,
        type: 'bafa',
        searchable: true
    },
    bafd_initial: {
        name: 'Bafd - Formation générale',
        short: 'Formation générale',
        active: true,        
        step: 1,
        type: 'bafd',
        searchable: true
    },
    bafd_intership1: {
        name: 'Bafd - Stage pratique n°1',
        short: 'Stage pratique n°1',
        active: false,        
        step: 2,
        type: 'bafd',
        searchable: false
    },
    bafd_advanced: {
        name: 'Bafd - Perfectionnement',
        short: 'Perfectionnement',
        active: true,        
        step: 3,
        type: 'bafd',
        searchable: true
    },
    bafd_intership2: {
        name: 'Bafd - Stage pratique n°2',
        short: 'Stage pratique n°2',
        active: false,        
        step: 4,
        type: 'bafd',
        searchable: false
    },
}

export const accomodationTypes = {
    day_school: {
        name: 'Externat',
        icon: 'fi fi-rr-backpack'
    },
    half_board: {
        name: 'Demi-pension',
        icon: 'fi fi-rr-plate-eating'
    },
    full_board: {
        name: 'Pension complète',
        icon: 'fi fi-rr-bed'
    },
    mixed: {
        name: 'Mixte',
        icon: 'fi fi-rr-bed' // TODO
    }
}

export const thematics = {
    environment: {
        name: 'Découverte de l\'environnement',
        icon: 'fi fi-rr-bio-leaves'
    },
    public_knowledge: {
        name: 'Connaissance du public',
        icon: 'fi fi-rr-age-alt'
    },
    science: {
        name: 'Sciences et technologies',
        icon: 'fi fi-rr-test-tube'
    },
    health_assistant: {
        name: 'Assistant sanitaire',
        icon: 'fi fi-rr-bandage-wound'
    },
    manual_activities: {
        name: 'Activités manuelles et d\'expressions',
        icon: 'fi fi-rr-hand-wave'
    },
    physical_activities: {
        name: 'Activitiés physiques et jeux',
        icon: 'fi fi-rr-whistle'
    },
    types_of_reception: {
        name: 'Les différents types d\'accueil',
        icon: 'fi fi-rr-people-roof'
    }
}
