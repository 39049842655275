'use client'

import React, { ReactNode, useEffect } from "react";
import aos from 'aos'
import { Events } from "@/services/theme";

type AOSProps = {
    children: ReactNode
}

export default function AOS({
    children
} : AOSProps) {

    useEffect(() => {
        aos.init()    
    }, [])

    return (
        <>
            {children}
        </>
    )
}