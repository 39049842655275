'use client'

import React, { useState, useEffect } from 'react'
import { useSearchParams } from "next/navigation"
import { useHandleSearchFromSearchBar } from "@/components/search/useHandleSearchFromSearchBar"
import { useSearchBar } from '@/components/search/useSearchBar'

type SearchBarProps = {
    search?: any,
    setSearch?: Function,
    handleSearch?: Function
}

export default function SearchBar({    
    search,
    setSearch,
    handleSearch
}: SearchBarProps) {

    useSearchBar()    

    return (
        <>
            <div id="multi" className="mainSearch bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 mt-20">
                <div className="button-grid items-center">
                    <div className="px-30 lg:py-20 lg:px-0">
                        <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Rechercher</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    handleSearch()
                                }}>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Un lieu, des activités, une formation, ..."
                                        value={search['query'] ?? ''}
                                        onChange={(e) => {
                                            const {
                                                query,
                                                ...otherSearchParams
                                            } = search
                                            setSearch({
                                                ...otherSearchParams,
                                                ...(e.target.value && { query: e.target.value })
                                            })
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Enter') return
                                            handleSearch()
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="button-item">
                        <button className="mainSearch__submit button col-12 rounded-full bg-primary text-white" onClick={() => handleSearch()}>
                            <span className="lancerrecherche mr-10 d-lg-none">
                                Lancer la recherche
                            </span>
                            <i className="icon-search text-20" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}