import Image from "next/image"
import LoadingSVG from "@/assets/loading.svg"

export default function Loading() {
    return (
        <div className="w-100 d-flex justify-content-center p-5">
            <div className="h-40 w-40">
                <Image src={LoadingSVG} alt="Loading" height={40} width={40} />
            </div>
        </div>
    )
}