'use client'

import { ReactNode, useEffect, useRef } from "react"

type CarouselProps = {
    id: string,
    defaultInterval?
}

export function Carousel({
    id,
    defaultInterval = 5000
}: CarouselProps) {

    useEffect(() => {
        const load = async () => {

            console.log('init carousel')

            const slides = document.getElementById(id).querySelectorAll('.carousel-item')
            slides.forEach((el) => {
                if (el.querySelector('video') !== null) {
                    el.setAttribute('data-bs-interval', (el.querySelector('video').duration ? el.querySelector('video').duration * 1000 : defaultInterval))
                }
                else {
                    el.setAttribute('data-bs-interval', defaultInterval)
                }
            })

            const bootstrap = (await import('bootstrap/dist/js/bootstrap')).default;
            new bootstrap.Carousel(document.getElementById(id), {                
                ride: true
            })
            console.log('init carousel end')
        }

        load()

    }, [])

    return (
        <></>
    )
}