import moment from "moment"
import { createObjectPagePathname, getMediaUrl } from "@/services/pathname"
import 'moment/locale/fr'
moment.locale('fr')
import Link from "next/link"
import { categories } from "@/services/educationCourse"
import imgWellbeing from "@/assets/img/gallery/educform-2.jpg"
import imgCitizenship from "@/assets/img/gallery/educform-1.jpg"

type SmallCardProps = {
    aroeven: Aroeven,
    educationCourse: EducationCourse
}

export default function SmallCard({
    aroeven,
    educationCourse
}: SmallCardProps) {

    const color = {
        wellbeing: 'theme2',
        citizenship: 'theme1'
    }[educationCourse.category] ?? ''

    const target = {
        adults: 'Adultes',
        children: 'Enfants / ados'
    }[educationCourse.target] ?? ''

    return (
        <>
            <Link href={createObjectPagePathname('EducationCourse', educationCourse)} className="vignette mini -type-1 educform">
                <div className="d-flex flex-row">
                    <div className="vignette__image">
                        <div className="cardImage ratio ratio-1:1">
                            <div className="cardImage__content">
                                <img src={getMediaUrl(educationCourse.media?.url ?? '#')} alt="image" />
                            </div>
                        </div>
                        <div className="bandeau theme2" />
                    </div>
                    <div className="vignette__content">
                        <h4 className="vignette__title d-flex flex-wrap mt-2">{educationCourse.name}</h4>
                        <div className={"type " + color}>{categories[educationCourse.category]?.name}</div>
                        <span className="public text-primary-3 text-12">
                            <i className="fi fi-rr-group" />
                            Public : {target}
                        </span>
                    </div>
                </div>
            </Link>
        </>
    )
}